import create from 'zustand'
import type { User, UserStore, UserStoreState } from './types'
// import { asMany, asOne } from '../../helpers/as'
import maybeDevtools from '../../helpers/zustand'
import api from '../../helpers/api'
import config from 'app/config'


const initialUserState: UserStoreState = {
    attemptedSignIn: false,
    attemptedUserLoad: false,
    serverError: false,
    showAccountMenu: false,
    user: null,
    authenticated: false,
    userGroups: {
        byId: {},
        allIds: [],
        selectedId: null,
    },
}

const useUserStore = create<UserStore>(
    maybeDevtools<UserStore>(
        (set, get) => ({
            ...initialUserState,
            actions: {
                signIn: async ({ email, password }) => {
                    const response = await api.post<{ user: User, token: string }>('/auth/sign-in', { data: { email, password }, session: true })
                    if (response.success) {
                        set({
                            user: response.data.user,
                            authenticated: true,
                        })
                        return { success: true, error: null }
                    }
                    return { success: false, error: response.code}
                },
                signOut: async () => {
                    const response = await api.get<{}>('/auth/sign-out', { session: false })
                    if (response.success) {
                        set({
                            user: null,
                            authenticated: false,
                        })
                    }
                },
                forgotPassword: async (email) => {
                    const response = await api.post<{}>('/auth/recover-password', { data: { email, redirect: config.host + '/recover-password/'} })
                    if (response.success) {
                        return { success: true, error: null }
                    }
                    return { success: false, error: response.code }
                },
                emailToken: async (token) => {
                    const response = await api.get<{ user: User, token: string }>('/auth/email-token/' + token, { session: true })
                    if (response.success) { 
                        set({
                            user: response.data.user,
                            authenticated: true,
                        })
                    }
                    return response.success
                },
                authenticationToken: async (token) => {
                    const response = await api.get<{ user: User, token: string }>('/auth/authentication-token/' + token, { session: true })
                    if (response.success) { 
                        set({
                            user: response.data.user,
                            authenticated: true,
                        })
                    }
                    return response.success
                },
                register: async ({ email, password }) => {
                    const response = await api.post('/auth/register', { data: { email, password, redirect: config.host + '/email-token/' }})
                    if (response.success) return { success: true, error: null }
                    return { success: false, error: 'EMAIL_ALLREADY_EXIST'}
                },
                session: async () => {
                    const response = await api.get<{ user: User, session: boolean }>('/auth')
                    if (response.success) {
                        set({
                            user: response.data.session ? response.data.user : null,
                            attemptedUserLoad: true,
                            serverError: false,
                            authenticated: response.data.session
                        })
                    } else {
                        set({
                            user: null,
                            attemptedUserLoad: true,
                            serverError: true,
                            authenticated: false,
                        })
                    }
                },
                updateUser: async (data) => {
                    const response = await api.put<{ user: User }>('/auth', { data: { ...data, redirect: config.host + '/email-token/'} })
                    if (response.success) {
                        set({
                            user: response.data.user,
                        })
                        return { success: true, error: null }
                    }
                    return { success: false, error: response.code }
                },
                updateProfile: async (data) => {
                    const response = await api.put<{ user: User }>('/auth/profile', { data })
                    if (response.success) {
                        set({
                            user: response.data.user,
                        })
                        return {success: true, error: null}
                    }
                    return {success: true, error: null}
                },
                uploadProfilePicture: async (pictureFile) => {
                    const response = await api.put<{ user: User }>('/auth/profile', { formData: { pictureFile } })
                    if (response.success) {
                        set({
                            user: response.data.user,
                        })
                        return { success: true, error: null }
                    }
                    return { success: false, error: response.code }
                },
                removeProfilePicture: async () => {
                    const response = await api.put<{ user: User }>('/auth/profile', { data: { pictureFile: null } })
                    if (response.success) {
                        set({
                            user: response.data.user,
                        })
                        return { success: true, error: null }
                    }
                    return { success: false, error: response.code }
                },

            },
        }),
        { name: 'user', anonymousActionType: 'user.action' }
    )
)
export default useUserStore