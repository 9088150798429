import React from 'react'




type Props = {
    //
}

const Main : React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
    return (
        <>
            <main className="mt-12 md:mt-16 lg:mt-20 ml-0 md:ml-16 lg:ml-20 grow">
                <div className="container mx-auto p-4 md:p-8 lg:p-12">
                    { children }
                </div>
            </main>
        </>
    )
}
export default Main