import { vClass } from 'app/helpers'
import { useTimeout } from 'app/hooks'
import useNotificationsStore from 'app/store/notifications'
import { Notification } from 'app/store/notifications/types'
import React from 'react'
import shallow from 'zustand/shallow'





/**
 * Notifications
 */
const Notifications: React.FC = () => {
    const list = useNotificationsStore(state => state.notifications, shallow)
    return list.length > 0 ? (
            <div className={vClass(
                'fixed bottom-0 right-0 w-96 flex flex-col gap-4 p-4',
            )}>
                {list.map((notification) => (
                    <Toast notification={notification} key={`notification-${notification.id}`} />
                ))}
            </div>
    ) : <></>
}


export default Notifications


/**
 * Toast
 */
type ToastProps = {
    notification: Notification
}
const Toast: React.FC<ToastProps> = ({ notification }) => {
    const timer = 10000
    const remove = useNotificationsStore(state => state.actions.remove)
    useTimeout(() => remove(notification), timer)

    return (
        <>
            <div 
                className={vClass(
                    'relative flex p-4 gap-4 bg-white border-l-4 cursor-pointer',
                    notification.type === 'success' ? 'border-green-500' :
                    notification.type === 'error' ? 'border-red-500' :
                    notification.type === 'info' ? 'border-blue-500' :
                    notification.type === 'warning' ? 'border-amber-500' :
                    'border-neutral-800',
                )}
                onClick={() => remove(notification)}
            >
                <div className="flex justify-center items-start">
                    <span className={vClass(
                        'flex justify-center items-center w-8 h-8 rounded-full',
                        'text-white',
                        notification.type === 'success' ? 'bg-green-500' :
                        notification.type === 'error' ? 'bg-red-500' :
                        notification.type === 'info' ? 'bg-blue-500' :
                        notification.type === 'warning' ? 'bg-amber-500' :
                        'bg-neutral-800',
                    )}>
                        {!!notification.icon ? 
                            notification.icon : 
                            notification.type === 'success' ? (
                                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.31 411.31C309.056 417.563 298.936 417.563 292.682 411.31L160 278.627L27.318 411.31C21.064 417.563 10.944 417.563 4.69 411.31C-1.563 405.056 -1.563 394.936 4.69 388.682L137.373 256L4.69 123.318C-1.563 117.064 -1.563 106.944 4.69 100.69C10.944 94.437 21.064 94.437 27.318 100.69L160 233.373L292.682 100.69C298.936 94.437 309.056 94.437 315.31 100.69C321.563 106.944 321.563 117.064 315.31 123.318L182.627 256L315.31 388.682C321.563 394.936 321.563 405.056 315.31 411.31Z"/></svg>
                            ) : notification.type === 'error' ? (
                                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.31 411.31C309.056 417.563 298.936 417.563 292.682 411.31L160 278.627L27.318 411.31C21.064 417.563 10.944 417.563 4.69 411.31C-1.563 405.056 -1.563 394.936 4.69 388.682L137.373 256L4.69 123.318C-1.563 117.064 -1.563 106.944 4.69 100.69C10.944 94.437 21.064 94.437 27.318 100.69L160 233.373L292.682 100.69C298.936 94.437 309.056 94.437 315.31 100.69C321.563 106.944 321.563 117.064 315.31 123.318L182.627 256L315.31 388.682C321.563 394.936 321.563 405.056 315.31 411.31Z"/></svg>
                            ) : notification.type === 'warning' ? (
                                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><path d="M64 400C46.326 400 32 414.326 32 432S46.326 464 64 464S96 449.674 96 432S81.674 400 64 400ZM64 352C72.844 352 80 344.844 80 336V48C80 39.156 72.844 32 64 32S48 39.156 48 48V336C48 344.844 55.156 352 64 352Z"/></svg>
                            ) : (
                                <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M96 96C113.674 96 128 81.674 128 64S113.674 32 96 32S64 46.326 64 64S78.326 96 96 96ZM176 448H112V176C112 167.156 104.844 160 96 160H48C39.156 160 32 167.156 32 176S39.156 192 48 192H80V448H16C7.156 448 0 455.156 0 464S7.156 480 16 480H176C184.844 480 192 472.844 192 464S184.844 448 176 448Z"/></svg>
                        )}                        
                    </span>
                </div>
                <div className="flex flex-col gap-2">
                    <h3 className="text-lg font-bold">{notification.title}</h3>
                    <p className="font-light">{notification.message}</p>
                </div>
                <div className="absolute inset-x-0 bottom-0 ">
                    <div 
                        className="h-2 bg-neutral-200 animate-progress" 
                        style={{animation: `progress ${timer}ms ease-in-out reverse`}} 
                    />
                </div>
                <button 
                    className="absolute top-4 right-4"
                    onClick={() => remove(notification)}
                >
                    <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.31 411.31C309.056 417.563 298.936 417.563 292.682 411.31L160 278.627L27.318 411.31C21.064 417.563 10.944 417.563 4.69 411.31C-1.563 405.056 -1.563 394.936 4.69 388.682L137.373 256L4.69 123.318C-1.563 117.064 -1.563 106.944 4.69 100.69C10.944 94.437 21.064 94.437 27.318 100.69L160 233.373L292.682 100.69C298.936 94.437 309.056 94.437 315.31 100.69C321.563 106.944 321.563 117.064 315.31 123.318L182.627 256L315.31 388.682C321.563 394.936 321.563 405.056 315.31 411.31Z"/></svg>
                </button>
            </div>
        </>
    )
}