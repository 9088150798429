import { FC, useCallback, useState } from 'react'
import useEffectOnce from 'app/hooks/useEffectOnce'
import useAuthStore from 'app/store/auth'
import PageLoader from 'components/SessionLoader'
import Button from 'components/Button'
import Layout from 'layouts/Website'
import useNotificationsStore from 'app/store/notifications'


type Props = {
    token: string
}

const EmailToken: FC<Props> = ({ token }) => {
    const emailToken = useAuthStore(state => state.actions.emailToken)
    const sendNotification = useNotificationsStore(state => state.actions.send)
    const [ loading, setLoading ] = useState<boolean>(true)
    const [ success, setSuccess ] = useState<boolean>(false)
    const checkEmailToken = useCallback(async () => {
        const response = await emailToken(token)
        if (response) {
            setSuccess(true)
            sendNotification({type: 'success', title: 'You are connected', message: `And your e-mail address has been validated`})
        } else setSuccess(false)
        setLoading(false)
    }, [ emailToken, token ])

    useEffectOnce(() => {
        checkEmailToken()
    })
    // si loading = true loader
    return (
        <Layout>
            <div className="flex w-full grow items-center justify-center pt-20">
                {loading ? ( <PageLoader /> ) : success ? (
                    <section className="max-w-xl px-8 pt-16 pb-8 rounded-xl shadow-md bg-white">
                        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                            <div className="max-w-md text-center">
                                <h2 className="mb-8 font-extrabold text-7xl bg-gradient-to-r from-indigo-600 to-blue-400 bg-clip-text text-transparent">Well done</h2>
                                <p className="mt-4 mb-8 text-neutral-600 text-lg">Your email address has been validated.</p>
                                <Button href="/dashboard" as="link">Take a look on my dashboard</Button>
                            </div>
                        </div>
                    </section>
                ) : (
                    <section className="max-w-xl px-8 pt-16 pb-8 rounded-xl shadow-md bg-white">
                        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                            <div className="max-w-md text-center">
                                <h2 className="mb-8 font-extrabold text-7xl bg-gradient-to-r from-indigo-600 to-blue-400 bg-clip-text text-transparent">Aouch!</h2>
                                <p className="text-2xl font-semibold md:text-3xl">This link is no longer / not correct</p>
                                <p className="mt-4 mb-8 text-neutral-600 text-lg">If you used this link to validate your email address, it may have already been done.</p>
                                <Button href="/" as="link">Back to homepage</Button>
                            </div>
                        </div>
                    </section>
                ) }
            </div>
        </Layout>
    )

}
export default EmailToken