import { vClass } from 'app/helpers'
import { useCopyToClipboard } from 'app/hooks'
import React, { useCallback, useState } from 'react'

type CopyToClipboardProps = {
    content: string
}

/**
 * CopyToClipboard
 */
const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ content }) => {

    const copy = useCopyToClipboard()[1]
    const [ done, setDone ] = useState<boolean>(false)
    const copyToClipboard = useCallback(() => {
        copy(content)
        setDone(true)
        setTimeout(() => setDone(false), 1000)
    }, [ content, copy ])

    return (
        <button 
            className={vClass(
                'relative flex justify-center items-center w-6',
            )}
            onClick={() => copyToClipboard()}
        >
            <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M272 416C263.164 416 256 423.162 256 432V448C256 465.672 241.674 480 224 480H64C46.326 480 32 465.672 32 448V192C32 174.326 46.326 160 64 160H176C184.836 160 192 152.836 192 144C192 135.162 184.836 128 176 128H64C28.654 128 0 156.652 0 192V448C0 483.346 28.654 512 64 512H224C259.346 512 288 483.346 288 448V432C288 423.162 280.836 416 272 416ZM502.627 86.627L425.373 9.373C419.371 3.371 411.232 0 402.746 0H288C252.654 0 224 28.652 224 64V320C224 355.346 252.654 384 288 384H448C483.346 384 512 355.346 512 320V109.254C512 100.768 508.629 92.629 502.627 86.627ZM416 45.254L466.746 96H416V45.254ZM480 320C480 337.672 465.674 352 448 352H288C270.326 352 256 337.672 256 320V64C256 46.326 270.326 32 288 32H384V96C384 113.672 398.326 128 416 128H480V320Z"/></svg>
            {done && (
                <span className="absolute -top-1 left-full ml-2 px-2 py-1 rounded-md bg-neutral-900 text-white text-sm">
                    <span className="absolute top-2.5 left-0 w-2 h-2 rotate-45 -translate-x-1/2 bg-neutral-900"/>
                    Copied
                </span>
            )}
        </button>
    )
}
export default CopyToClipboard