import create from 'zustand'
import type { LoaderStore, LoaderStoreState } from './types'
import maybeDevtools from '../../helpers/zustand'
import { v4 as uuid } from 'uuid'


const initialLoaderState: LoaderStoreState = {
    loader: false,
}

const useLoaderStore = create<LoaderStore>(
    maybeDevtools<LoaderStore>(
        (set, get) => ({
            ...initialLoaderState,
            actions: {
                start: () => {
                    set(state => ({
                        loader: true
                    }))
                },
                stop: () => {
                    set(state => ({
                        loader: false
                    }))
                },
            },
        }),
        { name: 'loader', anonymousActionType: 'loader.action' }
    )
)
export default useLoaderStore