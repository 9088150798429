import { FC, useCallback, useMemo, useState } from 'react'
import { Link } from 'wouter'
import Button from 'components/Button'
import useUserStore from 'app/store/auth'
import { REGEX, ucFirst } from 'app/helpers'
import FormText from 'components/FormText'
import FormValidate from 'components/FormValidate'
import Layout from 'layouts/Website'


const ForgotPassword: React.FC = () => {
    const forgotPassword = useUserStore(state => state.actions.forgotPassword)
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ step, setStep ] = useState<number>(1)

    const [ validate, setValidate ] = useState<boolean>(false)
    const [ error, setError ] = useState<null|string>(null)
    const [ formData, setFormData ] = useState<{ email:string }>({
        email: 'yann@101.lu'
    })

    const validation = useMemo(() => {
        const email = formData.email.match(REGEX.mail) !== null
        return {
            email,
            form: email
        }
    }, [ formData ])

    const onSignIn = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setValidate(true)
        setError(null)
        if(loading || !validation.form) return
        setLoading(true)
        const { success, error } = await forgotPassword(formData.email)
        setLoading(false)
        if (success) return setStep(2)
        setError(error)
    }, [formData, loading, forgotPassword, validation.form])

    return (
        <Layout>
            <div className="flex w-full grow items-center justify-center pt-20">
                <section className="container mx-auto p-4 sm:p-8 lg:p-12">
                    <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 rounded-lg bg-white shadow-lg">
                        <div className="p-8 lg:p-12">
                            {step === 1 && (
                                <>
                                    <h1 className="mb-2 text-center text-4xl font-bold">Forgot <span className="text-blue-500">password</span></h1>
                                    {!!error ? (
                                        <p className="mb-8 text-lg text-center text-red-500">
                                            {error === 'INVALID_CREDENTIALS' && 'Email address does not correspond to any account!'}
                                        </p>
                                    ) : (
                                        <p className="mb-8 text-lg text-center text-neutral-600">Give me your email address,<br /> I will give you an email to reset your password.</p>
                                    )}
                                    <form className="flex flex-col gap-8" onSubmit={onSignIn}>
                                        <div className="flex flex-col gap-2">
                                            <label htmlFor="email" className="text-lg text-neutral-800 font-semibold">Email address</label>
                                            <FormValidate
                                                isValid={validation.email}
                                                validate={validate}
                                                message="The email address must be an email address not a username, CNS code or anything else"
                                            >
                                                <FormText 
                                                    name="email"
                                                    placeholder="why-me@101.lu"
                                                    value={formData.email}
                                                    setValue={(value) => setFormData({ ...formData, email: value })}
                                                    labelIcon={(
                                                        <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M288 128H160C124.75 128 96 156.75 96 192V208C96 269.75 146.25 320 208 320H240C301.75 320 352 269.75 352 208V192C352 156.75 323.25 128 288 128ZM320 208C320 252.125 284.125 288 240 288H208C163.875 288 128 252.125 128 208V192C128 174.375 142.25 160 160 160H288C305.75 160 320 174.375 320 192V208ZM304 400H144C117.49 400 96 421.49 96 448V496C96 504.8 103.2 512 112 512H112C120.8 512 128 504.8 128 496V448C128 439.2 135.2 432 144 432H304C312.8 432 320 439.2 320 448V496C320 504.8 327.2 512 336 512H336C344.8 512 352 504.8 352 496V448C352 421.49 330.51 400 304 400ZM272 448C263.25 448 256 455.25 256 464S263.25 480 272 480S288 472.75 288 464S280.75 448 272 448ZM176 448C167.25 448 160 455.25 160 464V496C160 504.8 167.2 512 176 512H176C184.8 512 192 504.8 192 496V464C192 455.25 184.75 448 176 448ZM359.25 328.25C379.5 308.25 395.125 283.5 404.875 256H416C424.8 256 432 248.8 432 240V144C432 135.2 424.8 128 416 128H404.75C378.5 53.5 307.625 0 224 0S69.5 53.5 43.25 128H32C23.2 128 16 135.2 16 144V240C16 248.8 23.2 256 32 256H43.25C52.875 283.5 68.625 308.25 88.875 328.25C37.125 347 0 396.25 0 454.375V496C0 504.8 7.2 512 16 512H16C24.8 512 32 504.8 32 496V454.375C32 402.75 70.5 360.375 120.25 353.375C150.25 372.75 185.75 384 224 384S297.75 372.75 327.75 353.375C377.5 360.25 416 402.75 416 454.375V496C416 504.8 423.2 512 432 512L432 512C440.8 512 448 504.8 448 496V454.375C448 396.25 410.875 347 359.25 328.25ZM224 352C135.75 352 64 280.25 64 192S135.75 32 224 32S384 103.75 384 192S312.25 352 224 352Z"/></svg>
                                                    )}
                                                />
                                            </FormValidate>
                                        </div>
                                        <div className="flex justify-end">
                                            <Button type="submit" className="w-full">
                                                {loading && (
                                                    <svg role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/></svg>
                                                )}
                                                Help me please
                                            </Button>
                                        </div>
                                        <div className="flex justify-center flex-wrap gap-2 text-lg text-neutral-600">
                                            Recovered password? <Link href="/sign-in" className="text-blue-500">Go quickly sign in here.</Link>
                                        </div>
                                    </form>
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <h1 className="mb-2 text-center text-4xl font-bold text-blue-500">Congratulations!</h1>
                                    <p className="text-2xl text-center text-neutral-600 mb-4">
                                        Password recover request done
                                    </p>
                                    <p className="text-lg text-center text-neutral-600 mb-12">
                                        Thanks { ucFirst(formData.email.split('@')[0]) },<br />
                                        A message has been sent to your e-mail. After confirmation of your address, you will create a new password for your account.
                                    </p>
                                    <div className="flex justify-end">
                                        <Button as="link" href="/sign-in" className="w-full">
                                            Go to the sign in page
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="overflow-hidden rounded-t-lg lg:rounded-r-lg lg:rounded-tl-none">
                            <img 
                                src="/images/placeholder/upset-man-making-facepalm-looking-away-concerned-forgot-something-important-slap-forehead-troubled-standing-against-blue-background_1258-65489.webp" 
                                alt="sign-in placeholder" 
                                className="w-full aspect-[16/7] lg:aspect-auto lg:h-full object-cover blur"
                            />
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )

}
export default ForgotPassword