import Button from 'components/Button'
import Layout from 'layouts/Website'
import { FC } from 'react'


const ServerError: FC = () => {
    return (
        <Layout>
            <div className="flex w-full grow items-center justify-center pt-20">
                <section className="max-w-xl px-8 pt-16 pb-8 rounded-xl shadow-md bg-white">
                    <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
                        <div className="max-w-md text-center">
                            <h2 className="mb-8 font-extrabold text-9xl bg-gradient-to-r from-indigo-600 to-blue-400 bg-clip-text text-transparent">
                                <span className="sr-only">Error</span>500
                            </h2>
                            <p className="text-2xl font-semibold md:text-3xl">Sorry, Something goes wrong on our server.</p>
                            <p className="mt-4 mb-8 text-neutral-600 text-lg">Try to go to the home page and if the problem persists, please call the doctor.</p>
                            <Button href="/" as="link">Go to homepage</Button>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}
export default ServerError