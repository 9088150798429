import { FC } from 'react'
import { Redirect, Route, Switch, useLocation } from "wouter"
import useUserStore from 'app/store/auth'
import useEffectOnce from 'app/hooks/useEffectOnce'
import SessionLoader from 'components/SessionLoader'
import Notifications from 'components/Notifications'
import Loader from 'components/Loader'

// Pages
import Home from './pages'
import SignIn from './pages/SignIn'
import SignOut from 'pages/SignOut'
import Register from 'pages/Register'
import EmailToken from 'pages/EmailToken'
import Dashboard from 'pages/Dashboard'
import Profile from 'pages/Dashboard/Profile'
import Signature from 'pages/Dashboard/Signature'
import NotFound from 'pages/NotFound'
import Admin from 'pages/Admin'
import Campaigns from 'pages/Admin/Hacks/Campaigns'
import ForgotPassword from 'pages/Admin/ForgotPassword'
import RecoverPassword from 'pages/RecoverPassword'
import Staff from 'pages/Dashboard/Staff'
import ServerError from 'pages/ServerError'
import TestPage from 'pages/Dashboard/test-page'


const App: FC = () => {
    

    /**
     * Session
     */
    const session = useUserStore(state => state.actions.session)
    const { attemptedUserLoad, serverError } = useUserStore(state => ({ attemptedUserLoad: state.attemptedUserLoad, serverError: state.serverError }))
    useEffectOnce(() => void session())
    const [location, setLocation] = useLocation();
    if (!attemptedUserLoad) return (<SessionLoader />)



    if (serverError && location !== '/500') {
        session()
        setLocation('/500')
    }// return (<Redirect to="/500" />)

    /**
     * Router
     */
    return (
        <>
            <Switch>




                {/**
                 * Common
                 */}
                <Route path="/email-token/:token">
                    {params => <EmailToken token={params.token} />}
                </Route>
                <Route path="/recover-password/:token">
                    {params => <RecoverPassword token={params.token} />}
                </Route>




                {/**
                 * Authenticated
                 */}
                <RouteAuthenticated path="/dashboard">
                    <Dashboard />
                </RouteAuthenticated>
                <RouteAuthenticated path="/dashboard/profile">
                    <Profile />
                </RouteAuthenticated>
                <RouteAuthenticated path="/dashboard/signature">
                    <Signature />
                </RouteAuthenticated>
                <RouteAuthenticated path="/dashboard/staff">
                    <Staff />
                </RouteAuthenticated>
                <RouteAuthenticated path="/sign-out" redirect="/">
                    <SignOut />
                </RouteAuthenticated>
                <RouteAuthenticated path="/dashboard/test-page">
                    <TestPage />
                </RouteAuthenticated>




                {/**
                 * Admin
                 */}
                <RouteAdmin path="/admin">
                    <Admin />
                </RouteAdmin>
                <RouteAdmin path="/admin/hacks/campaigns">
                    <Campaigns />
                </RouteAdmin>





                {/**
                 * Unauthenticated
                 */}
                <RouteUnauthenticated path="/">
                    <Home />
                </RouteUnauthenticated>
                <RouteUnauthenticated path="/sign-in">
                    <SignIn />
                </RouteUnauthenticated>
                <RouteUnauthenticated path="/forgot-password">
                    <ForgotPassword />
                </RouteUnauthenticated>
                <RouteUnauthenticated path="/register">
                    <Register />
                </RouteUnauthenticated>




                {/**
                 * Fallback & errors
                 */}
                <Route path="/500">
                    <ServerError />
                </Route>
                <Route path="/404">
                    <NotFound />
                </Route>
                <Route>
                    <Redirect to="/sign-in" />
                </Route>

            </Switch>
            <Notifications />
            <Loader />
        </>
    )
}
export default App







type RouteAuthenticatedProps = {
    path: string
    redirect?: string
}
/**
 * RouteAuthenticated
 */
const RouteAuthenticated: React.FC<React.PropsWithChildren<RouteAuthenticatedProps>> = ({ 
    path, 
    redirect = '/sign-in',
    children,
}) => {
    const authenticated = useUserStore(state => state.authenticated)
    if (authenticated) {
        return (
            <Route path={path}>
                {children}
            </Route>
        )
    }
    return (
        <Route path={path}>
             <Redirect to={redirect} />
        </Route>
    )
}







type RouteUnauthenticatedProps = {
    path: string
    redirect?: string
}
/**
 * RouteUnauthenticated
 */
const RouteUnauthenticated: React.FC<React.PropsWithChildren<RouteUnauthenticatedProps>> = ({ 
    path,
    redirect = '/dashboard',
    children 
}) => {
    const authenticated = useUserStore(state => state.authenticated)
    if (authenticated) {
        return (
            <Route path={path}>
                <Redirect to={redirect} />
            </Route>
        )
    }
    return (
        <Route path={path}>
            {children}
        </Route>
    )
}







type RouteAdminProps = {
    path: string
    redirect?: string
}
/**
 * RouteUnauthenticated
 */
const RouteAdmin: React.FC<React.PropsWithChildren<RouteAdminProps>> = ({ 
    path, 
    redirect = '/dashboard',
    children 
}) => {
    const isAdmin = useUserStore(state => state.user?.right === 'admin')
    if (isAdmin) {
        return (
            <Route path={path}>
                {children}
            </Route>        
        )
    }
    return (
        <Route path={path}> 
            <Redirect to={redirect} />
        </Route>
    )
}