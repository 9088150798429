import Layout from 'layouts/Admin'
import { FC } from 'react'


const Dashboard: FC = () => {

    return (
        <Layout>
            <div className="flex flex-col gap-12">
                <div className="grid grid-cols-3 gap-12">
                    <div className="aspect-square rounded-lg bg-white shadow-lg" />
                    <div className="aspect-square rounded-lg bg-white shadow-lg opacity-90" />
                    <div className="aspect-square rounded-lg bg-white shadow-lg opacity-75" />
                    <div className="aspect-square rounded-lg bg-white shadow-lg opacity-90" />
                    <div className="aspect-square rounded-lg bg-white shadow-lg opacity-75" />
                    <div className="aspect-square" />
                    <div className="aspect-square rounded-lg bg-white shadow-lg opacity-75" />
                </div>
            </div>
        </Layout>
    )
}
export default Dashboard