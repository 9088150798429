








const config: Config = {
    api: process.env.REACT_APP_API_URL,
    environment: process.env.NODE_ENV,
    host: process.env.REACT_APP_BASE_URL,
    company: {
        name: '101 Studios S.A.',
        shortName: '101',
        address: '17, Allée Louis Ackermann',
        zip: 'L-1897',
        city: 'Kockelscheuer',
        country: 'Luxembourg',
        phone: '+352 26 58 14 66',
        website: 'www.101.lu',
        facebook: 'https://www.facebook.com/101Studioslu',
        instagram: 'https://www.instagram.com/101studios.lu',
        linkedin: 'https://www.linkedin.com/company/101-studios',
        IBLC: '2014 2201 330',
        TVA: 'LU26653771',
        RCS: 'LU26653771',
        banks: [
            { name: 'ING', IBAN: 'LU61 0141 7493 3690 0000', BIC: 'CELLLULL' },
            { name: 'Spuerkeess', IBAN: 'LU78 0019 4255 9389 6000', BIC: 'BCEELULL' },
        ],
    },

}
export default config