import React, { FC, useState, useCallback, useEffect, useRef } from 'react'
import shallow from 'zustand/shallow'
import useUserStore from 'app/store/auth'
import { User } from 'app/store/auth/types'
import MacLayout from 'components/MacLayout'
import config from 'app/config'
import { clone } from 'ramda'
import FormText from 'components/FormText'
import Button from 'components/Button'
import copyToClipboard from 'copy-to-clipboard'
import Layout from 'layouts/Admin'

const Signature: FC = () => {
    const user = useUserStore(
        state => state.user,
        shallow
    ) as User
    const [ formData, setFormData ] = useState(clone(signatureEmpty))
    const initFormData = useCallback(() => {
        const profileData = clone(user.profile)
        const phoneCompany = {
            name: 'Phone',
            number: config.company.phone
        }
        setFormData({ 
            firstname: profileData.firstname,
            lastname: profileData.lastname,
            position: profileData.position,
            phones: [ ...profileData.phones, phoneCompany],
            emails: profileData.emails,
        })
    }, [ user ])

    useEffect(() => {// Init formdata on first render
        if (user) initFormData()
    }, [ user, initFormData ])

    const addPhone = useCallback(() => {
        setFormData({ ...formData, phones: [ ...formData.phones, { name: 'Phone' , number: ''} ]})
    }, [ formData, setFormData ])
    
    const removePhone = useCallback((phone: { name: string, number: string }) => {
        const phones = formData.phones
        phones.splice(phones.indexOf(phone), 1)
        setFormData({ ...formData, phones })
    }, [ formData, setFormData ])

    const addEmail = useCallback(() => {
        setFormData({ ...formData, emails: [ ...formData.emails, { name: 'Email' , address: ''} ]})
    }, [ formData, setFormData ])

    const removeEmail = useCallback((email: { name: string, address: string }) => {
        const emails = formData.emails
        emails.splice(emails.indexOf(email), 1)
        setFormData({ ...formData, emails })
    }, [ formData, setFormData ])

    /**
     * Signature clipboard
     */
    const signatureRef = useRef<HTMLDivElement>(null)
    const [ copySignatureDone, setCopySignatureDone ] = useState(false)
    const copySignature = useCallback(() => {
        if (!signatureRef?.current) return
        setCopySignatureDone(true)
        copyToClipboard(signatureRef.current.innerHTML, {
            format: 'text/html'
        })
        setTimeout(() => setCopySignatureDone(false), 1000)
    }, [ signatureRef ])
    const [ copyHTMLSignatureDone, setCopyHTMLSignatureDone ] = useState(false)
    const copyHTMLSignature = useCallback(() => {
        if (!signatureRef?.current) return
        setCopyHTMLSignatureDone(true)
        copyToClipboard(signatureRef.current.innerHTML, {
            format: 'text/plain'
        })
        setTimeout(() => setCopyHTMLSignatureDone(false), 1000)
    }, [ signatureRef ])

    return (
        <Layout>
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-12">
                <div className="flex flex-col bg-white p-4 md:p-8 xl:p-12 rounded-lg">
                    <div className="flex flex-col gap-4">
                        <FormText
                            name="firstname"
                            placeholder="First name"
                            value={formData.firstname}
                            setValue={(value) => setFormData({ ...formData, firstname: value})}
                            labelIcon={(
                                <svg className="fill-current w-6 h-6 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M576.002 64H63.998C28.654 64 0 92.652 0 128V384C0 419.346 28.654 448 63.998 448H576.002C611.346 448 640 419.348 640 384V128C640 92.654 611.346 64 576.002 64ZM608 384C608 401.645 593.646 416 576.002 416H63.998C46.354 416 32 401.645 32 384V128C32 110.355 46.354 96 63.998 96H576.002C593.646 96 608 110.355 608 128V384ZM405.594 248.484C412.156 239.312 416 228.094 416 216C416 185.125 390.875 160 360 160H304C295.156 160 288 167.156 288 176V336C288 344.844 295.156 352 304 352H376C406.875 352 432 326.875 432 296C432 275.984 421.438 258.375 405.594 248.484ZM320 192H360C373.219 192 384 202.766 384 216S373.219 240 360 240H320V192ZM376 320H320V272H376C389.219 272 400 282.766 400 296C400 309.219 389.219 320 376 320ZM190.844 170.062C186.031 157.906 165.969 157.906 161.156 170.062L97.156 330.062C93.875 338.25 97.844 347.562 106.062 350.844C114.281 354.156 123.562 350.125 126.844 341.938L142.17 303.631C142.799 303.705 143.352 304 144 304H208C208.648 304 209.201 303.705 209.83 303.631L225.156 341.938C227.656 348.188 233.656 352 240 352C241.969 352 244 351.625 245.938 350.844C254.156 347.562 258.125 338.25 254.844 330.062L190.844 170.062ZM154.826 272L176 219.078L197.174 272H154.826Z"/></svg>
                            )}
                        />
                        <FormText
                            name="lastname"
                            placeholder="Last name"
                            value={formData.lastname}
                            setValue={(value) => setFormData({ ...formData, lastname: value})}
                            labelIcon={(
                                <svg className="fill-current w-6 h-6 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M576.002 64H63.998C28.654 64 0 92.652 0 128V384C0 419.346 28.654 448 63.998 448H576.002C611.346 448 640 419.348 640 384V128C640 92.654 611.346 64 576.002 64ZM608 384C608 401.645 593.646 416 576.002 416H63.998C46.354 416 32 401.645 32 384V128C32 110.355 46.354 96 63.998 96H576.002C593.646 96 608 110.355 608 128V384ZM405.594 248.484C412.156 239.312 416 228.094 416 216C416 185.125 390.875 160 360 160H304C295.156 160 288 167.156 288 176V336C288 344.844 295.156 352 304 352H376C406.875 352 432 326.875 432 296C432 275.984 421.438 258.375 405.594 248.484ZM320 192H360C373.219 192 384 202.766 384 216S373.219 240 360 240H320V192ZM376 320H320V272H376C389.219 272 400 282.766 400 296C400 309.219 389.219 320 376 320ZM190.844 170.062C186.031 157.906 165.969 157.906 161.156 170.062L97.156 330.062C93.875 338.25 97.844 347.562 106.062 350.844C114.281 354.156 123.562 350.125 126.844 341.938L142.17 303.631C142.799 303.705 143.352 304 144 304H208C208.648 304 209.201 303.705 209.83 303.631L225.156 341.938C227.656 348.188 233.656 352 240 352C241.969 352 244 351.625 245.938 350.844C254.156 347.562 258.125 338.25 254.844 330.062L190.844 170.062ZM154.826 272L176 219.078L197.174 272H154.826Z"/></svg>
                            )}
                        />
                        <FormText
                            name="position"
                            placeholder="Position"
                            value={formData.position}
                            setValue={(value) => setFormData({ ...formData, position: value})}
                            labelIcon={(
                                <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M112 352H48C21.49 352 0 373.49 0 400V464C0 490.51 21.49 512 48 512H112C138.51 512 160 490.51 160 464V400C160 373.49 138.51 352 112 352ZM128 464C128 472.822 120.822 480 112 480H48C39.178 480 32 472.822 32 464V400C32 391.178 39.178 384 48 384H112C120.822 384 128 391.178 128 400V464ZM352 352H288C261.49 352 240 373.49 240 400V464C240 490.51 261.49 512 288 512H352C378.51 512 400 490.51 400 464V400C400 373.49 378.51 352 352 352ZM368 464C368 472.822 360.822 480 352 480H288C279.178 480 272 472.822 272 464V400C272 391.178 279.178 384 288 384H352C360.822 384 368 391.178 368 400V464ZM592 352H528C501.49 352 480 373.49 480 400V464C480 490.51 501.49 512 528 512H592C618.51 512 640 490.51 640 464V400C640 373.49 618.51 352 592 352ZM608 464C608 472.822 600.822 480 592 480H528C519.178 480 512 472.822 512 464V400C512 391.178 519.178 384 528 384H592C600.822 384 608 391.178 608 400V464ZM80 320C88.844 320 96 312.844 96 304V272C96 263.172 103.188 256 112 256H304V304C304 312.844 311.156 320 320 320S336 312.844 336 304V256H528C536.812 256 544 263.172 544 272V304C544 312.844 551.156 320 560 320S576 312.844 576 304V272C576 245.531 554.469 224 528 224H336V160H368C394.51 160 416 138.51 416 112V48C416 21.49 394.51 0 368 0H272C245.49 0 224 21.49 224 48V112C224 138.51 245.49 160 272 160H304V224H112C85.531 224 64 245.531 64 272V304C64 312.844 71.156 320 80 320ZM272 128C263.178 128 256 120.822 256 112V48C256 39.178 263.178 32 272 32H368C376.822 32 384 39.178 384 48V112C384 120.822 376.822 128 368 128H272Z"/></svg>
                            )}
                        />
                        {formData.phones.map((phone, index) => (
                            <div className="flex flex-col gap-2" key={`inputPhone${index}`}>
                                <input 
                                    type="text" 
                                    className="w-full bg-white font-bold" 
                                    placeholder="Phone name"
                                    value={phone.name}
                                    onChange={({ target }) => setFormData({ ...formData, phones: formData.phones.map((p, i) => p === phone ? { name: target.value, number: p.number } : p)})}
                                />
                                <div className="relative flex">
                                    <FormText 
                                        name={`phone-${index}`}
                                        placeholder="+352 123 456 789"
                                        value={phone.number}
                                        setValue={(value) => setFormData({ ...formData, phones: formData.phones.map((p, i) => p === phone ? { name: p.name, number: value } : p)})}
                                        labelIcon={(
                                            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M288 0H96C60.654 0 32 28.654 32 64V448C32 483.346 60.654 512 96 512H288C323.346 512 352 483.346 352 448V64C352 28.654 323.346 0 288 0ZM320 448C320 465.645 305.645 480 288 480H96C78.355 480 64 465.645 64 448V64C64 46.355 78.355 32 96 32H288C305.645 32 320 46.355 320 64V448ZM224 400H160C151.164 400 144 407.162 144 416C144 424.836 151.164 432 160 432H224C232.836 432 240 424.836 240 416C240 407.162 232.836 400 224 400Z"/></svg>
                                        )}
                                    />
                                    <button 
                                        className="absolute top-0 right-0 flex justify-center items-center shrink-0 w-12 h-12"
                                        onClick={() => removePhone(phone)}
                                    >
                                        <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256C432 264.844 424.844 272 416 272H32C23.156 272 16 264.844 16 256S23.156 240 32 240H416C424.844 240 432 247.156 432 256Z"/></svg>
                                    </button>
                                </div>
                            </div>
                        ))}
                        <Button 
                            onClick={() => addPhone()}
                            secondary
                            className="border-dashed"
                        >
                            <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256C432 264.844 424.844 272 416 272H240V448C240 456.844 232.844 464 224 464S208 456.844 208 448V272H32C23.156 272 16 264.844 16 256S23.156 240 32 240H208V64C208 55.156 215.156 48 224 48S240 55.156 240 64V240H416C424.844 240 432 247.156 432 256Z"/></svg>
                            Add a phone number
                        </Button>
                        {formData.emails.map((email, index) => (
                            <div className="flex flex-col gap-2" key={`inputEmail${index}`}>
                                <input 
                                    type="text" 
                                    className="w-full rounded bg-white font-bold" 
                                    id="address"
                                    placeholder="Phone name"
                                    value={email.name}
                                    onChange={({ target }) => setFormData({ ...formData, emails: formData.emails.map((e, i) => e === email ? { name: target.value, address: e.address } : e)})}
                                />
                                <div className="relative flex">
                                    <FormText 
                                        name={`email-${index}`}
                                        placeholder="why-me@101.lu"
                                        value={email.address}
                                        setValue={(value) => setFormData({ ...formData, emails: formData.emails.map((e, i) => e === email ? { name: e.name, address: value } : e)})}
                                        labelIcon={(
                                            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M496 192C487.164 192 480 199.162 480 208V464C480 472.836 472.836 480 464 480H48C39.164 480 32 472.836 32 464V208C32 199.162 24.836 192 16 192S0 199.162 0 208V464C0 490.51 21.49 512 48 512H464C490.51 512 512 490.51 512 464V208C512 199.162 504.836 192 496 192ZM64.875 276.338C65.158 277.324 65.428 278.266 65.885 279.164C66.32 280.027 66.896 280.77 67.492 281.549C68.187 282.459 68.885 283.299 69.756 284.039C70.051 284.289 70.199 284.643 70.516 284.875L203.281 382.594C218.703 393.969 236.938 400 256 400S293.297 393.969 308.703 382.594L441.484 284.875C441.801 284.643 441.949 284.289 442.244 284.039C443.115 283.299 443.813 282.459 444.508 281.549C445.104 280.77 445.68 280.027 446.115 279.164C446.572 278.266 446.842 277.324 447.125 276.338C447.428 275.277 447.684 274.26 447.766 273.162C447.797 272.758 448 272.412 448 272V48C448 21.531 426.469 0 400 0H112C85.531 0 64 21.531 64 48V272C64 272.412 64.203 272.758 64.234 273.162C64.316 274.26 64.572 275.277 64.875 276.338ZM96 48C96 39.188 103.172 32 112 32H400C408.828 32 416 39.188 416 48V263.92L289.719 356.844C269.937 371.469 242.078 371.469 222.266 356.844L96 263.92V48ZM352 240C352 231.162 344.836 224 336 224H176C167.164 224 160 231.162 160 240C160 248.836 167.164 256 176 256H336C344.836 256 352 248.836 352 240ZM176 160H336C344.836 160 352 152.836 352 144C352 135.162 344.836 128 336 128H176C167.164 128 160 135.162 160 144C160 152.836 167.164 160 176 160Z"/></svg>
                                        )}
                                    />
                                    <button 
                                        className="absolute top-0 right-0 flex justify-center items-center shrink-0 w-12 h-12"
                                        onClick={() => removeEmail(email)}
                                    >
                                        <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256C432 264.844 424.844 272 416 272H32C23.156 272 16 264.844 16 256S23.156 240 32 240H416C424.844 240 432 247.156 432 256Z"/></svg>
                                    </button>
                                </div>
                            </div>
                        ))}
                        <Button 
                            onClick={() => addEmail()}
                            secondary
                            className="border-dashed"
                        >
                            <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256C432 264.844 424.844 272 416 272H240V448C240 456.844 232.844 464 224 464S208 456.844 208 448V272H32C23.156 272 16 264.844 16 256S23.156 240 32 240H208V64C208 55.156 215.156 48 224 48S240 55.156 240 64V240H416C424.844 240 432 247.156 432 256Z"/></svg>
                            Add an email address
                        </Button>
                        <div className="flex flex-col justify-center gap-4">
                            <Button onClick={copySignature}>
                                {copySignatureDone ? 'The signature have been copied!' : 'Copy to clipboard' }
                            </Button>
                            
                            <Button onClick={copyHTMLSignature}>
                                {copyHTMLSignatureDone ? 'The signature have been copied!' : 'Copy in HTML' }
                            </Button>
                        </div>                                
                    </div>
                </div>
                <div className="xl:col-span-2">
                    <MacLayout className="w-full">
                        <div ref={signatureRef}>
                            <Signature101 member={formData} company={config.company} image={'https://signatures.my101.lu/101/'} />
                        </div>
                    </MacLayout>
                </div>
            </div>
        </Layout>
    )
}
export default Signature

const signatureEmpty = {
    firstname: '',
    lastname: '',
    position: '',
    phones: [{ name: 'Phone', number: '' }],
    emails: [{ name: 'Email', address: '' }]
}


/**
 * Signature 101
 */
type PropsSignature101 = {
    member: {
        firstname: string
        lastname: string
        position: string
        phones: { name: string, number: string }[]
        emails: { name: string, address: string }[]
    }
    company: {
        name: string
        shortName: string
        phone: string
        website: string
        facebook: string
        instagram: string
        linkedin: string
        address: string
        zip: string
        city: string
        country: string
        IBLC: string
        TVA: string
        RCS: string
        banks: { name: string, IBAN: string, BIC: string }[]
    }
    image: string
}
const Signature101: FC<PropsSignature101> = ({ member, company, image }) => {
    const fontFamily = 'Poppins, Tahoma, sans-serif'
    const fontWeightMedium = '600'
    const fontWeightLight = '300'
    const primary = '#FF5F0F'
    const color = '#000000'
    return (
        <>
            <img src={image + 'logo-101.png'} alt="101" width="60" height="11" style={{
                width: '60px',
                height: '11px',
                border: '0px',
                display: 'inline-block',
            }} />
            <span style={{
                fontSize: '10pt',
                fontFamily: fontFamily,
                fontWeight: fontWeightLight,
                color,
            }}>
                designing values
            </span><br />

            <div style={{
                    padding: '8pt 0',
            }} >
                <span style={{
                    fontSize: '10pt',
                    fontFamily: fontFamily,
                    fontWeight: fontWeightMedium,
                    color,
                }}>
                    {member.firstname} {member.lastname}
                </span>
                <span style={{
                    fontSize: '10pt',
                    fontFamily: fontFamily,
                    fontWeight: fontWeightLight,
                    color,
                }}>&nbsp;|&nbsp;</span>
                <span style={{
                    fontSize: '10pt',
                    fontFamily: fontFamily,
                    fontWeight: fontWeightLight,
                    color,
                }}>{member.position}</span>

                {member.emails.map((email, index) => (
                    <React.Fragment key={`signature101-email-${index}`}>
                    <br /><a href={'mailto:' + email.address} style={{
                        fontSize: '9pt',
                        fontFamily: fontFamily,
                        fontWeight: fontWeightLight,
                        color: primary,
                        lineHeight: '17pt',
                        textDecoration: 'none'
                    }}>{email.address}</a>
                    </React.Fragment>
                ))}
                
            </div>


            <span style={{
                fontSize: '9pt',
                fontFamily: fontFamily,
                fontWeight: fontWeightMedium,
                color,
                lineHeight: '18pt',
            }}>
                {company.name}
            </span><br />

            {member.phones.map((phone, index) => (
                <React.Fragment key={`signature101-phone-${index}`}>
                <span style={{//href={'tel:' + phone.number}
                    fontSize: '8pt',
                    fontFamily: fontFamily,
                    fontWeight: fontWeightLight,
                    color,
                    lineHeight: '12pt',
                    textDecoration: 'none'
                }}>{phone.name === 'Phone' ? '' : phone.name} {phone.number}</span>
                <br />
                </React.Fragment>
            ))}

            <span style={{
                fontSize: '8pt',
                fontFamily: fontFamily,
                fontWeight: fontWeightLight,
                color,
                lineHeight: '12pt',
            }}>
                {company.address}<br />{company.zip} {company.city}
            </span><br />

            <a href={'https://' + company.website} style={{
                fontSize: '8pt',
                fontFamily: fontFamily,
                fontWeight: fontWeightLight,
                color: primary,
                lineHeight: '17pt',
                textDecoration: 'none',
            }}>{company.website}</a>


            <div style={{
                    padding: '8pt 0',
                }} >
                <a href={company.facebook}>
                    <img src={image + 'button-facebook.png'} alt="Facebook" width="111" height="30" style={{
                    width: '111px',
                    height: '30px',
                    border: '0px',
                    display: 'inline-block',
                }}  />
                </a>
                <span>&nbsp; &nbsp;</span>
                <a href={company.instagram}>
                    <img src={image + 'button-instagram.png'} alt="Instagram" width="114" height="30" style={{
                    width: '114px',
                    height: '30px',
                    border: '0px',
                    display: 'inline-block',
                }}  />
                </a>
                <span>&nbsp; &nbsp;</span>
                <a href={company.linkedin}>
                    <img src={image + 'button-linkedin.png'} alt="Linkedin" width="100" height="30" style={{
                    width: '100px',
                    height: '30px',
                    border: '0px',
                    display: 'inline-block',
                }}  />
                </a>

            </div>
            



            <span style={{
                fontSize: '8pt',
                color: '#C0C0C0',
                fontFamily: fontFamily,
                fontWeight: fontWeightLight,
                lineHeight: '12pt',
            }}>
                {`IBLC: ${company.IBLC} . TVA: ${company.TVA} . RCS: ${company.TVA}`}<br />
                {company.banks.map((bank, index) => (
                    <React.Fragment key={`signature101-bank-${index}`}>
                        {`${bank.name} . ${bank.IBAN} . B.I.C.: ${bank.BIC}`}<br />
                    </React.Fragment>
                ))}
            </span>
        </>
    )
}