import { colorFromString, vClass } from 'app/helpers'
import React, { useRef, useState, useCallback, useMemo } from 'react'
import config from 'app/config'
import { User, UserProfile } from 'app/store/auth/types'
import useAuthStore from 'app/store/auth'
import shallow from 'zustand/shallow'
import useUsersStore from 'app/store/users'
import { useEffectOnce } from 'app/hooks'



type ProfilePictureProps = {
    user: User
    size: string
    editable?: boolean
    admin?: boolean
}
const ProfilePicture: React.FC<ProfilePictureProps> = ({ 
    user, 
    size, 
    editable = false,
    admin = false
}) => {
    const me = useAuthStore(state => state.user) as User
    const getAllUsers = useUsersStore(state => state.actions.getAllUsers)

    const uploadProfilePicture = useAuthStore(state => state.actions.uploadProfilePicture)
    const removeProfilePicture = useAuthStore(state => state.actions.removeProfilePicture)

    const pictureInputRef = useRef<HTMLInputElement>(null)
    const [ picturePreview, setPicturePreview ] = useState<string>('')
    
    const onPictureChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const objectUrl = URL.createObjectURL(e.target.files[0])
            setPicturePreview(objectUrl)
            await uploadProfilePicture(e.target.files[0])
            setPicturePreview('')
        }
    }, [ uploadProfilePicture ])
    
    const onPictureRemove = useCallback(async () => {
        await removeProfilePicture()
    }, [ removeProfilePicture ])

    const profile = useMemo<UserProfile>(() => user.id === me.id ? me.profile : user.profile, [user, me])
    const username = useMemo<string>(() => user.id === me.id ? me.username : user.username, [user, me])

    return (
        <div className={vClass(
            'group relative shrink-0',
            size === '36' ? 'w-36 h-36 text-5xl' :
            size === '32' ? 'w-32 h-32 text-4xl' :
            size === '28' ? 'w-28 h-28 text-3xl' :
            size === '12' ? 'w-12 h-12 text-xl' :
            size === '10' ? 'w-10 h-10 text-xl' :
            size === '8' ? 'w-8 h-8 text-xl' :
            'w-16 h-16 text-xl'
        )}>
            {!profile.picture ? (
                <span className={vClass(
                    'flex justify-center items-center w-full h-full  rounded-full text-white',
                    colorFromString(username, ['bg-red-500', 'bg-orange-500', 'bg-amber-500', 'bg-yellow-500', 'bg-lime-500', 'bg-green-500', 'bg-emerald-500', 'bg-teal-500', 'bg-cyan-500', 'bg-sky-500', 'bg-blue-500', 'bg-violet-500', 'bg-fuchsia-500', 'bg-pink-500', 'bg-rose-500']),
                )}>
                    {username ? username[0]: '?'}
                </span>
            ) : (
                <img 
                    src={picturePreview ? picturePreview : config.api + profile.picture} 
                    alt={username} 
                    className={vClass(
                        'w-full h-full object-cover rounded-full',
                    )} 
                />
            )}
            {editable && user.id === me.id && (
                <>
                    {!!profile.picture && (
                        <button 
                            className={vClass(
                                'absolute top-0 right-0',
                                'flex justify-center items-center w-5 h-5 rounded-full',
                                'bg-neutral-600 text-white',
                                'opacity-0 group-hover:opacity-75 transition-opacity duration-300 ease-in-out',
                            )}
                            onClick={onPictureRemove}
                        >
                            <svg className="w-3 h-3 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.31 411.31C309.056 417.563 298.936 417.563 292.682 411.31L160 278.627L27.318 411.31C21.064 417.563 10.944 417.563 4.69 411.31C-1.563 405.056 -1.563 394.936 4.69 388.682L137.373 256L4.69 123.318C-1.563 117.064 -1.563 106.944 4.69 100.69C10.944 94.437 21.064 94.437 27.318 100.69L160 233.373L292.682 100.69C298.936 94.437 309.056 94.437 315.31 100.69C321.563 106.944 321.563 117.064 315.31 123.318L182.627 256L315.31 388.682C321.563 394.936 321.563 405.056 315.31 411.31Z"/></svg>
                        </button>
                    )}
                    <button 
                        className={vClass(
                            'absolute bottom-0 right-0',
                            'flex justify-center items-center w-12 h-12 rounded-full',
                            'bg-neutral-600 text-white',
                            'opacity-0 group-hover:opacity-75 transition-opacity duration-300 ease-in-out',
                        )}
                        onClick={() => pictureInputRef.current?.click()}
                    >
                        <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M80 64H144C152.836 64 160 56.836 160 48C160 39.162 152.836 32 144 32H80C71.164 32 64 39.162 64 48C64 56.836 71.164 64 80 64ZM448 32H303.105C293.17 32 283.371 34.312 274.484 38.756L192 80H64C28.654 80 0 108.652 0 144V416C0 451.346 28.654 480 64 480H448C483.346 480 512 451.346 512 416V96C512 60.652 483.346 32 448 32ZM480 416C480 433.672 465.674 448 448 448H64C46.326 448 32 433.672 32 416V288H128C128 358.594 185.422 416 256 416S384 358.594 384 288H480V416ZM160 288C160 235.062 203.062 192 256 192S352 235.062 352 288S308.938 384 256 384S160 340.938 160 288ZM480 256H379.457C365.146 200.943 315.467 160 256 160S146.854 200.943 132.543 256H32V144C32 126.326 46.326 112 64 112H184.443C194.381 112 204.18 109.686 213.066 105.242L288.793 67.379C293.236 65.156 298.137 64 303.105 64H448C465.674 64 480 78.326 480 96V256Z"/></svg>
                    </button>
                    <input
                        type="file"
                        className="w-0 h-0 opacity-0"
                        accept=".jpg, .jpeg, .png, .gif"
                        onChange={onPictureChange}
                        ref={pictureInputRef}
                    />
                </>
            )}

        </div>
    )
}
export default ProfilePicture