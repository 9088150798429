import useLoaderStore from 'app/store/loader'
import { FC } from 'react'


const Loader: FC = () => {
    const loader = useLoaderStore(state => state.loader)
    return loader ? (
        <div className="fixed z-[100] top-0 inset-x-0 h-1 bg-gradient-to-r from-indigo-400 to-indigo-600">
            <div 
                className="absolute top-0 right-full bottom-0 left-0 w-0 bg-indigo-300/50" 
                style={{
                    animation: 'animate-scrollbar 3s linear infinite'
                }}
            />
            <div 
                className="absolute top-0 right-full bottom-0 left-0 w-0 bg-indigo-600/50" 
                style={{
                    animation: 'animate-scrollbar 2s linear infinite'
                }}
            />
            <div 
                className="absolute top-0 right-full bottom-0 left-0 w-0 bg-blue-600/50" 
                style={{
                    animation: 'animate-scrollbar 1s linear infinite'
                }}
            />
        </div>
    ) : <></>
}
export default Loader