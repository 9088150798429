import Button from 'components/Button'
import Layout from 'layouts/Admin'
import { reduce, omit } from 'ramda'
import { FC } from 'react'


// @ts-ignore
const chars = [...'0123456789abcdefghijklmnopqrstuvwxyz'] as string []
const ids: string[] = reduce<string, string[]>((a1, p1) => [...a1, ...reduce<string, string[]>((a2, p2) => [ ...a2, p1+p2 ], [], chars)], [], chars)
    .sort(( a, b) => Math.random() < 0.5 ? 1 : -1)
    .splice(0, 200)

const generateId = (list: { [key: string]: string }) => 
    omit(Object.keys(list), ids)[0]

const TestPage: FC = () => {

    const test = {
        [ids[0]]: 'Facebook',
        [ids[1]]: 'Insta',
        [ids[2]]: 'FR',
        [ids[3]]: 'DE',
        [ids[4]]: 'Facebook IT',
    }
    
    const onClick = () => {
        console.log(JSON.stringify(ids));
        
        // console.log(generateId(test))
    }

    return (
        <Layout>
            <div className="flex flex-col gap-12">
                <div className="flex justify-center items-center">
                    <Button secondary onClick={onClick}>Click</Button>    
                </div>
            </div>
        </Layout>
    )
}
export default TestPage