import { useState, useRef, type FC, type Dispatch, type FocusEvent  } from "react"
import { vClass } from "app/helpers"
import { useUpdateEffect } from "app/hooks"

type Props = {
    value: string
    setValue: Dispatch<string>
    labelIcon?: JSX.Element
    placeholder?: string
    name?: string
    isValid?: boolean
    validate?: boolean
}

/**
 * Form Text
 */
const FormDate: FC<Props> = ({ value, setValue, labelIcon, placeholder = '', name = 'form-text', isValid, validate }) => {
    const fieldRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLButtonElement>(null)
    const [ showPassword, setShowPassword ] = useState(false)

    /**
     * Manage cursor position and focus
     */
    const [ cursorPosition, setCursorPosition ] = useState([0, 0])
    const onBlur = ({ relatedTarget, target }: FocusEvent<HTMLInputElement>) => {
        if (relatedTarget === passwordRef?.current) // Blur to toggle password button
            setCursorPosition([target.selectionStart || 0, target.selectionEnd || 0])// backup cursor position
        else setCursorPosition([value.length, value.length])// set cursor position to the end
    }
    useUpdateEffect(() => {// Delay focus after next render
        fieldRef?.current?.setSelectionRange(cursorPosition[0], cursorPosition[1])
        fieldRef?.current?.focus()
    }, [ showPassword ])

    /**
     * Render
     */
    return (
        <div className="relative flex w-full h-12">
            {!!labelIcon && (
                <label 
                    htmlFor={name}
                    className="absolute inset-y-0 left-0 flex justify-center items-center w-12 h-12 text-neutral-500"
                    aria-hidden="true"
                >
                    {labelIcon}
                </label>
            )}
            <input 
                className={vClass(
                    'flex justify-between items-center grow h-full pr-4 leading-4 bg-white border border-neutral-500 rounded-lg text-lg text-left placeholder:text-neutral-500',
                    labelIcon ? 'pl-12' : 'pl-4',
                    {
                        'border border-red-200 shadow-red-200': validate && !isValid
                    }
                )}
                type={'date'}
                id={name}
                name={name}
                ref={fieldRef}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                onBlur={onBlur}
                placeholder={placeholder}
            />
        </div>
    )
}
export default FormDate