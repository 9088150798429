import React from "react"
import { vClass } from "app/helpers"





type Props = {
    className?: string
}


const MacLayout: React.FC<React.PropsWithChildren<Props>> = ({ className = '', children }) => {
    return (
        <div 
            className={vClass(
                'group rounded-xl overflow-hidden bg-neutral-700',
                className
            )}
        >
            <div className="flex gap-4 p-4">
                <span className="w-4 h-4 rounded-full border group-hover:border-red-600 group-hover:bg-red-500 border-neutral-500 bg-neutral-400 transition-colors duration-300 ease-in-out" />
                <span className="w-4 h-4 rounded-full border group-hover:border-green-600 group-hover:bg-green-500 border-neutral-500 bg-neutral-400 transition-colors duration-300 ease-in-out" />
                <span className="w-4 h-4 rounded-full border group-hover:border-yellow-600 group-hover:bg-yellow-500 border-neutral-500 bg-neutral-400 transition-colors duration-300 ease-in-out" />
            </div>
            <div className="p-4 md:p-8 xl:p-12 bg-white">
                {children}
            </div>
        </div>
    )
}
export default MacLayout