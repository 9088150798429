






export const colorFromString = (string: string, colors: string[]): string => 
colors[
    string ? 
        Math.round(string[0].charCodeAt(0) + string[string.length - 1].charCodeAt(0)) % colors.length :
        0
    ]