import React, { useState } from 'react'
import Header from './Header'
import Main from './Main'
import Sidebar from './Sidebar'




type Props = {
    //
}

const Layout : React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
    const [ menu, setMenu ] = useState('')
    return (
        <div className="flex flex-col min-h-screen">
            <Header { ...{ menu, setMenu } } />
            <Sidebar { ...{ menu, setMenu } } />
            <Main>
                {children}
            </Main>
        </div>
    )
}
export default Layout