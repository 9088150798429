import fetcher from 'app/helpers/fetcher'
import { rand, normalize } from 'app/helpers'
import { useEffectOnce } from 'app/hooks'
import PageLoader from 'components/SessionLoader'
import Layout from 'layouts/Admin'
import React, { useCallback, useMemo, useState } from 'react'
import FormText from 'components/FormText'
import FormValidate from 'components/FormValidate'
import Button from 'components/Button'
import { clone } from 'ramda'
import { Dialog, DialogBackdrop, DialogClose, DialogPannel } from 'components/Dialog'
import FormTextarea from 'components/FormTextarea'


const createEvent = () => ({fn: () => {}, id: Symbol()})



const api = 'https://my101.lu/'
const workspace101 = '9cea9aa7-fcb2-45a2-a2eb-a574c9b7b13f'
const credentials = {
    email: 'yann@101.lu',
    password: 'password'
}


const HacksCampaigns: React.FC = () => {
    const [ campaigns, setCampaigns ] = useState<Campaign[]>([])
    
    const [ loading, setLoading ] = useState(true)
    const getData = useCallback( async () => {
        let response = await fetcher.get<{session: boolean}>(api + 'auth/')
        if (!response.success) return 
        if(response.data.session === false)
            await fetcher.post<{}>(api + 'auth/sign-in', {data: credentials, session: true})

        const responseCampaigns = await fetcher.get<{campaigns: Campaign[]}>(api + 'workspaces/'+ workspace101 +'/campaigns')
        if (!responseCampaigns.success) return 
        setCampaigns(responseCampaigns.data.campaigns)
        setLoading(false)
    }, [])
    useEffectOnce(() => void getData())



    const [ createCampaign, setCreateCampaign ] = useState<boolean>(false)
    const saveCreatedCampaign = async () => {
        setCreateCampaign(false)
    }
    
    const [ updateCampaign, setUpdateCampaign ] = useState<Campaign | false>(false)
    const saveUpdatedCampaign = async () => {
        setUpdateCampaign(false)
    }
    
    const [ deleteCampaign, setDeleteCampaign ] = useState<Campaign | false>(false)
    const removeDeletedCampaign = async () => {
        setDeleteCampaign(false)
    }

    const [ search, setSearch ] = useState<string>('')
    const filteredCampaigns = useMemo(() => {
        return campaigns.filter(
            campaign => normalize(search).split(' ').some(
                term => (
                    normalize(campaign.name).includes(term) ||
                    normalize(campaign.description).includes(term)
                )
            )
        )
    }, [ campaigns, search ])


    return loading ? (
        <PageLoader />
    ) : (
        <>
            <Layout>
                <div className="flex flex-col mt-28 ml-28 mr-8 mb-8 gap-12">
                    <div className="relative flex justify-center rounded-lg bg-white overflow-hidden">
                        <img 
                            src="/images/placeholder/digital-social-marketing-budget-1024x547.jpeg" 
                            alt="Campaign" 
                            className="w-full h-60 object-cover rounded-lg blur-md" 
                        />
                    </div>
                    <nav className="flex gap-8">
                        <div className="relative grow flex">
                            <label 
                                htmlFor="search" 
                                className="absolute top-0 left-0 flex justify-center items-center w-16 h-16"
                            >
                                <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M507.312 484.688L365.85 343.227C397.041 306.848 416 259.676 416 208C416 93.125 322.875 0 208 0S0 93.125 0 208S93.125 416 208 416C259.676 416 306.848 397.039 343.225 365.852L484.687 507.312C487.812 510.438 491.906 512 496 512S504.188 510.438 507.312 507.312C513.562 501.062 513.562 490.938 507.312 484.688ZM208 384C110.953 384 32 305.047 32 208S110.953 32 208 32S384 110.953 384 208S305.047 384 208 384Z"/></svg>
                            </label>
                            <input
                                onChange={({ target }) => setSearch(target.value)}
                                value={search}
                                placeholder="Search..."
                                name="search"
                                id="search"
                                className="grow h-16 px-16 rounded-lg bg-white shadow-lg text-lg"
                            />
                            <button 
                                className="absolute top-0 right-0 flex justify-center items-center w-16 h-16"
                                onClick={() => setSearch('')}
                            >
                                <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.31 411.31C309.056 417.563 298.936 417.563 292.682 411.31L160 278.627L27.318 411.31C21.064 417.563 10.944 417.563 4.69 411.31C-1.563 405.056 -1.563 394.936 4.69 388.682L137.373 256L4.69 123.318C-1.563 117.064 -1.563 106.944 4.69 100.69C10.944 94.437 21.064 94.437 27.318 100.69L160 233.373L292.682 100.69C298.936 94.437 309.056 94.437 315.31 100.69C321.563 106.944 321.563 117.064 315.31 123.318L182.627 256L315.31 388.682C321.563 394.936 321.563 405.056 315.31 411.31Z"/></svg>
                            </button>
                        </div>
                        <button 
                            className="flex justify-center items-center w-16 h-16 rounded-full bg-blue-500 shadow-lg text-white"
                            onClick={() => setCreateCampaign(true)}
                        >
                            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256C432 264.844 424.844 272 416 272H240V448C240 456.844 232.844 464 224 464S208 456.844 208 448V272H32C23.156 272 16 264.844 16 256S23.156 240 32 240H208V64C208 55.156 215.156 48 224 48S240 55.156 240 64V240H416C424.844 240 432 247.156 432 256Z"/></svg>
                        </button>
                    </nav>
                    <div className="grid grid-cols-3 gap-12">
                        {filteredCampaigns.map(campaign => (
                            <div className="group relative flex flex-col gap-2 shadow-lg rounded-lg p-8 bg-white" key={`${campaign.id}`}>
                                <span className="flex justify-center items-center w-48 h-48 -mt-16 mx-auto rounded-full bg-white">
                                    <svg  className="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 0C408.875 0 401.812 2.375 396.031 7.031L311 75.062C268.281 109.188 214.656 128 160 128H64C28.656 128 0 156.656 0 192V288C0 323.344 28.656 352 64 352H160C214.656 352 268.281 370.812 310.969 404.938L396 472.969C400.844 476.844 409.812 480 416 480C440.906 480 448 457.219 448 448V32C448 23.25 441.031 0 416 0ZM160 320H64C46.355 320 32 305.645 32 288V192C32 174.355 46.355 160 64 160H160V320ZM415.801 447.828L330.951 379.943C290.984 347.992 242.539 328.135 192 322.129V157.869C242.531 151.863 290.986 132.008 330.992 100.049L415.865 32.145C415.889 32.168 415.91 32.18 415.928 32.18C415.963 32.18 415.986 32.139 415.998 32.039L416 32.037L416.055 446.928C416.016 447.176 415.92 447.506 415.801 447.828ZM191.703 383.516C191.438 374.672 184.266 368.156 175.219 368C166.391 368.266 159.437 375.656 159.703 384.484C160.859 422.484 188.062 455.031 188.312 455.312C191.906 459.516 193 466.422 190.953 472.172C189.297 476.859 185.797 480 182.25 480H130.781C128.328 480 125.844 478.484 124 475.922C122.812 474.25 94.953 434.219 96.031 384.344C96.219 375.516 89.219 368.188 80.375 368C71.969 367.75 64.219 374.828 64.031 383.656C62.75 442.938 94.266 489.297 97.906 494.453C105.875 505.609 117.859 512 130.781 512H182.25C199.594 512 214.844 500.562 221.094 482.906C227 466.391 223.672 447.375 212.797 434.703C212.594 434.453 192.469 409.453 191.703 383.516ZM496 192C487.164 192 480 199.162 480 208V272C480 280.836 487.164 288 496 288S512 280.836 512 272V208C512 199.162 504.836 192 496 192Z"/></svg>
                                </span>
                                <h2 className="text-xl">{campaign.name}</h2>
                                <p className="font-light text-neutral-600">{campaign.description}</p>
                                <button 
                                    className="absolute right-4 top-4 flex justify-center items-center w-16 h-16 rounded-full bg-blue-500 text-white"
                                    onClick={() => setUpdateCampaign(campaign)} 
                                >
                                    <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M560 480H240C231.164 480 224 487.162 224 496C224 504.836 231.164 512 240 512H560C568.836 512 576 504.836 576 496C576 487.162 568.836 480 560 480ZM493.256 56.262L455.744 18.748C443.248 6.252 426.868 0 410.489 0C394.106 0 377.729 6.25 365.229 18.75L45.114 338.885C36.545 347.451 30.586 358.275 27.928 370.094L0.319 492.854C-1.701 502.967 6.159 512 15.948 512C16.994 512 18.063 511.896 19.145 511.68C19.145 511.68 103.76 493.73 141.907 484.748C153.446 482.031 163.774 476.225 172.155 467.844C221.284 418.719 406.686 233.33 493.346 146.676C518.34 121.684 518.248 81.256 493.256 56.262ZM149.528 445.217C145.309 449.436 140.276 452.256 134.573 453.6C109.897 459.41 64.936 469.148 37.108 475.121L59.149 377.115C60.481 371.197 63.452 365.803 67.743 361.512L315.307 113.932L398.069 196.693L149.528 445.217ZM470.721 124.047L420.696 174.07L337.932 91.307L387.86 41.375C393.905 35.33 401.942 32 410.489 32S427.073 35.33 433.118 41.375L470.629 78.889C483.104 91.365 483.145 111.623 470.721 124.047Z"/></svg>
                                </button>
                                <button 
                                    className="absolute right-2 top-20 flex justify-center items-center w-8 h-8 rounded-full bg-red-500 text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out"
                                    onClick={() => setDeleteCampaign(campaign)}
                                >
                                    <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.31 411.31C309.056 417.563 298.936 417.563 292.682 411.31L160 278.627L27.318 411.31C21.064 417.563 10.944 417.563 4.69 411.31C-1.563 405.056 -1.563 394.936 4.69 388.682L137.373 256L4.69 123.318C-1.563 117.064 -1.563 106.944 4.69 100.69C10.944 94.437 21.064 94.437 27.318 100.69L160 233.373L292.682 100.69C298.936 94.437 309.056 94.437 315.31 100.69C321.563 106.944 321.563 117.064 315.31 123.318L182.627 256L315.31 388.682C321.563 394.936 321.563 405.056 315.31 411.31Z"/></svg>
                                </button>

                                <div className="flex grow items-end mt-12">
                                    <div className="grid grid-cols-3 w-full">
                                        <div className="flex flex-col items-center">
                                            <h3 className="text-sm text-blue-300">Shortlinks</h3>
                                            <p className="text-lg">{campaign.shortlinks.length}</p>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <h3 className="text-sm text-blue-300">Clicks</h3>
                                            <p className="text-lg">{rand(50, 10000)}</p>
                                        </div>
                                        <div className="flex flex-col items-center">
                                            <h3 className="text-sm text-blue-300">Created</h3>
                                            <p className="text-lg">{(new Date(campaign.createdAt)).toLocaleDateString("fr-FR")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Layout>
            <Dialog 
                onClose={() => setCreateCampaign(false)} 
                open={createCampaign}
            >
                <DialogBackdrop/>
                <DialogPannel center className="max-w-3xl">
                    <CampaignForm 
                        create 
                        onDiscard={() => setCreateCampaign(false)} 
                        onSave={() => false} 
                    />
                </DialogPannel>
            </Dialog>
            <Dialog 
                onClose={() => setUpdateCampaign(false)} 
                open={!!updateCampaign}
            >
                <DialogBackdrop/>
                <DialogPannel center className="max-w-3xl">
                    <CampaignForm 
                        update 
                        onDiscard={() => setUpdateCampaign(false)}
                        onSave={() => setUpdateCampaign(false)}
                        campaign={updateCampaign || undefined}
                    />
                </DialogPannel>
            </Dialog>
            <Dialog open={!!deleteCampaign} onClickOutside={() => setDeleteCampaign(false)}>
                <DialogBackdrop/>
                <DialogPannel center className="flex flex-col max-w-xl gap-4">
                    <h2 className="text-center text-4xl ">Are you sure ?</h2>
                    <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam recusandae nam ipsum doloribus dolor nisi eaque esse.</p>
                    <div className="flex justify-center gap-8">
                        <Button secondary onClick={() => setDeleteCampaign(false)}>Not really</Button>
                        <Button>Of course</Button>
                    </div>
                </DialogPannel>
            </Dialog>
        </>
    )

}
export default HacksCampaigns



type CampaignFormProps = {
    create?: boolean
    update?: boolean
    onDiscard?: () => void
    onSave?: (formData: CampaignFormData) => void
    campaign?: Campaign
}


const CampaignForm: React.FC<CampaignFormProps> = ({
    create = false, 
    update = false,
    onDiscard = () => false,
    onSave = () => false,
    campaign
}) => {

    /**
     * Init
     */
    const [ formData, setFormData ] = useState<CampaignFormData>(clone(campaign ?? emptyCampaign))
    const initFormData = useCallback(() => {
        setValidate(false)
        setFormData(clone(campaign ?? emptyCampaign))
    }, [ campaign ])

    /**
     * Validation
     */
    const [ validate, setValidate ] = useState(false)
    const validation = useMemo(() => {
        const name = !!formData.name
        const description = !!formData.description || formData.description === ''
        return {
            name, description,
            form: name && description,
        }
    }, [ formData ])

    /**
     * Discard
     */
    const onDiscardChanges = useCallback(() => {
        initFormData()
        onDiscard()
    }, [ onDiscard, initFormData ])

    /**
     * Change
     */
    const onSaveChanges = useCallback(async (e: React.FormEvent) => {
        e.preventDefault()
        // Validation
        if (!validation.form) return setValidate(true)
        onSave(formData)
    }, [ formData, onSave, validation.form ])

    /**
     * Render
     */
    return (
        <form 
            onSubmit={onSaveChanges}
            className="flex flex-col gap-12"
        >
            
            {(create || update) && (
                <h2 className="text-2xl text-center">
                    {create ? 'Create a new campaign' : ''}
                    {update ? 'Update your campaign' : ''}
                </h2>
            )}
            
            <p className="p-4 bg-slate-200 text-sm">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat ipsum libero est dolorum consequatur tempora sint dolorem temporibus magnam, repellendus maxime quasi ex praesentium fuga voluptatibus repudiandae qui amet ipsa!
            </p>

            <div className="flex flex-col gap-2">
                <label htmlFor="name" className="font-bold">Name</label>
                <FormValidate
                    message={'Error message'}
                    isValid={validation.name}
                    validate={validate}
                >
                    <FormText
                        setValue={value => setFormData({ ...formData, name: value })}
                        value={formData.name}
                        placeholder="Name"
                        name="name"
                        labelIcon={(
                            <svg  className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 0C408.875 0 401.812 2.375 396.031 7.031L311 75.062C268.281 109.188 214.656 128 160 128H64C28.656 128 0 156.656 0 192V288C0 323.344 28.656 352 64 352H160C214.656 352 268.281 370.812 310.969 404.938L396 472.969C400.844 476.844 409.812 480 416 480C440.906 480 448 457.219 448 448V32C448 23.25 441.031 0 416 0ZM160 320H64C46.355 320 32 305.645 32 288V192C32 174.355 46.355 160 64 160H160V320ZM415.801 447.828L330.951 379.943C290.984 347.992 242.539 328.135 192 322.129V157.869C242.531 151.863 290.986 132.008 330.992 100.049L415.865 32.145C415.889 32.168 415.91 32.18 415.928 32.18C415.963 32.18 415.986 32.139 415.998 32.039L416 32.037L416.055 446.928C416.016 447.176 415.92 447.506 415.801 447.828ZM191.703 383.516C191.438 374.672 184.266 368.156 175.219 368C166.391 368.266 159.437 375.656 159.703 384.484C160.859 422.484 188.062 455.031 188.312 455.312C191.906 459.516 193 466.422 190.953 472.172C189.297 476.859 185.797 480 182.25 480H130.781C128.328 480 125.844 478.484 124 475.922C122.812 474.25 94.953 434.219 96.031 384.344C96.219 375.516 89.219 368.188 80.375 368C71.969 367.75 64.219 374.828 64.031 383.656C62.75 442.938 94.266 489.297 97.906 494.453C105.875 505.609 117.859 512 130.781 512H182.25C199.594 512 214.844 500.562 221.094 482.906C227 466.391 223.672 447.375 212.797 434.703C212.594 434.453 192.469 409.453 191.703 383.516ZM496 192C487.164 192 480 199.162 480 208V272C480 280.836 487.164 288 496 288S512 280.836 512 272V208C512 199.162 504.836 192 496 192Z"/></svg>
                        )}
                    />                        
                </FormValidate>
            </div>

            <div className="flex flex-col gap-2">
                <label htmlFor="description" className="font-bold">Description</label>
                <FormValidate
                    message={'Error message'}
                    isValid={validation.description}
                    validate={validate}
                >
                    <FormTextarea
                        setValue={value => setFormData({ ...formData, description: value })}
                        value={formData.description}
                        placeholder="Description"
                        name="description"
                        labelIcon={(
                            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256.033 32C114.678 32 0.068 125.125 0.068 240C0.068 287.625 19.941 331.25 52.935 366.25C38.062 405.75 7.066 439.125 6.566 439.5C-0.057 446.5 -1.807 456.75 1.943 465.5C5.816 474.25 14.316 480 23.939 480C85.431 480 133.926 454.25 163.047 433.75C192.043 442.75 223.289 448 256.033 448C397.39 448 512 354.875 512 240S397.39 32 256.033 32ZM256.033 416C227.787 416 199.791 411.75 172.795 403.25C163.279 400.182 152.873 401.789 144.726 407.588L144.674 407.625C121.678 423.875 86.181 442.875 42.062 447.25C54.06 432.125 71.808 406.75 82.806 377.625L82.935 377.285C87.219 366.014 84.726 353.287 76.506 344.463L76.308 344.25C47.312 313.75 32.064 277.625 32.064 240C32.064 143 132.551 64 256.033 64C379.517 64 480.004 143 480.004 240S379.517 416 256.033 416ZM256 216C242.746 216 232 226.744 232 240C232 253.254 242.746 264 256 264S280 253.254 280 240C280 226.744 269.254 216 256 216ZM384 216C370.746 216 360 226.744 360 240C360 253.254 370.746 264 384 264S408 253.254 408 240C408 226.744 397.254 216 384 216ZM128 216C114.746 216 104 226.744 104 240C104 253.254 114.746 264 128 264S152 253.254 152 240C152 226.744 141.254 216 128 216Z"/></svg>
                        )}
                    />
                </FormValidate>
            </div>
            
            <div className="flex justify-end gap-8">
                <Button onClick={onDiscardChanges} secondary>Discard change</Button>
                <Button onClick={onSaveChanges}>Save change</Button>
            </div>
        </form>
    )
}



type Campaign = {
    id: string
    name: string
    description: string
    creator: User
    createdAt: string
    updatedAt: string
    shortlinks: Shortlink[]
}

type CampaignFormData = {
    id?: string
    name: string
    description: string
    creator?: User
    createdAt?: string
    updatedAt?: string
    shortlinks?: Shortlink[]
}



type Shortlink = {
    id: string
    name: string
    description: string | null
    secret: string | null
    url: string
    shortUrl: string
    createdAt: string
    updatedAt: string
}

type User = {
    id: string
    right: 'member' | 'admin'
    status: 'active'
    createdAt: string
    updatedAt: string
}

const emptyCampaign = {
    name: '',
    description: '',
}