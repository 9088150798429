import { useCallback, useEffect, useState, DependencyList } from 'react'
import { useEventListener } from './'

interface Size {
  width: number
  height: number
}

/**
 * This hook helps you to dynamically recover the scrollWidth and the scrollHeight of an HTML element. 
 * Dimensions are updated on load, on mount/un-mount, when resizing the window and when the ref changes.
 * @param {DependencyList} [deps=[]]
 * @returns {[
 *      setRef: Dispatch<SetStateAction<extends HTMLElement = HTMLDivElement | null>>, 
 *      size: { width: number, height: number }
 * ]}
 */
function useElementScrollSize<T extends HTMLElement = HTMLDivElement>(deps: DependencyList = []): [
  (node: T | null) => void,
  Size,
] {
  // Mutable values like 'ref.current' aren't valid dependencies
  // because mutating them doesn't re-render the component.
  // Instead, we use a state as a ref to be reactive.
  const [ref, setRef] = useState<T | null>(null)
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  })

  // Prevent too many rendering using useCallback
  const handleSize = useCallback(() => {
    setSize({
      width: ref?.scrollWidth || 0,
      height: ref?.scrollHeight || 0,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.scrollHeight, ref?.scrollWidth, ...deps])

  useEventListener('resize', handleSize)

  useEffect(() => {
    handleSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.scrollHeight, ref?.scrollWidth, ...deps])

  return [setRef, size]
}

export default useElementScrollSize
