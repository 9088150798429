import create from 'zustand'
import type { NotificationsStore, NotificationsStoreState } from './types'
import maybeDevtools from '../../helpers/zustand'
import { v4 as uuid } from 'uuid'


const initialNotificationsState: NotificationsStoreState = {
    notifications: [],
}

const useNotificationsStore = create<NotificationsStore>(
    maybeDevtools<NotificationsStore>(
        (set, get) => ({
            ...initialNotificationsState,
            actions: {
                send: (notification) => {
                    set(state => ({
                        notifications: [...state.notifications, {
                            ...notification,
                            id: uuid()
                        }]
                    }))
                },
                remove: (notification) => {
                    set(state => ({
                        notifications: state.notifications.filter(n => n.id !== notification.id)
                    }))
                },
            },
        }),
        { name: 'notifications', anonymousActionType: 'notifications.action' }
    )
)
export default useNotificationsStore