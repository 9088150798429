import create from 'zustand'
import type { UsersStore, UsersStoreState } from './types'
import maybeDevtools from '../../helpers/zustand'
import api from 'app/helpers/api'
import { User } from '../auth/types'
import { omit } from 'ramda'

const initialUsersState: UsersStoreState = {
    users: [],
}

const useUsersStore = create<UsersStore>(
    maybeDevtools<UsersStore>(
        (set, get) => ({
            ...initialUsersState,
            actions: {
                
                /**
                 * Get all users
                 */
                getAllUsers: async () => {
                    const response = await api.get<{ users: User[] }>('/users/', {})
                    if (response.success) {
                        set({
                            users: response.data.users
                        })
                        return { success: true, error: null }
                    }
                    return { success: false, error: response.code }
                },

                /**
                 * Admin : Get all users
                 */
                adminGetAllUsers: async () => {
                    const response = await api.get<{ users: User[] }>('/admin/users/', {})
                    if (response.success) {

                        set({
                            users: response.data.users
                        })
                        return { success: true, error: null }
                    }
                    return { success: false, error: response.code }
                },

                /**
                 * Admin : Update user
                 */
                adminUpdate: async (formData, picture) => {

                    // Update user
                    let response = await api.put<{ user: User }>(`/admin/users/${formData.id}`, { 
                        data: omit(['profile'], formData)
                    })
                    if (!response.success) return { success: false, error: response.code }

                    // Update profile
                    if (formData.profile) {
                        response = await api.put<{ user: User }>(`/admin/users/${formData.id}/profile` , { 
                            data: formData.profile
                        })
                        if (!response.success) return { success: false, error: response.code }
                    }
                    // Remove/Upload profile picture
                    if (picture !== undefined) {
                        response = await api.put<{ user: User }>(`/admin/users/${formData.id}/profile/picture`, { 
                            formData: picture === null ? 
                                { picture: '' } : // Remove
                                { pictureFile: picture } // Upload
                        })
                        if (!response.success) return { success: false, error: response.code }
                    }
                    set((state)=> ({// @ts-ignore
                        users: state.users.map(user => (user.id === response.data.user.id) ? response.data.user : user)
                    }))
                    return { success: true, error: null } 
                },

                /**
                 * Admin : Create user
                 */
                adminCreate: async (formData, picture) => {
                    // Update user
                    let response = await api.post<{ user: User }>(`/admin/users`, { 
                        data: omit(['profile'], formData)
                    })
                    if (!response.success) 
                        return { success: false, error: response.code }
                    const id = response.data.user.id
                    response = await api.put<{ user: User }>(`/admin/users/${id}/profile` , { 
                        data: formData.profile
                    })
                    if (!response.success) 
                        return { success: false, error: response.code }
                    if (picture) {
                        response = await api.put<{ user: User }>(`/admin/users/${id}/profile/picture`, { 
                            formData: { pictureFile: picture }
                        })
                        if (!response.success) return { success: false, error: response.code }
                    }
                    set((state)=> ({// @ts-ignore
                        users: [...state.users, response.data.user]
                    }))
                    return { success: true, error: null } 
                },
                
                /**
                 * Admin : Create user
                 */
                adminDelete: async (user) => {
                    let response = await api.delete<{ user?: User }>(`/admin/users/${user.id}`, {})
                    if (!response.success) 
                        return { success: false, error: response.code }

                    if(response.data.user) {// Soft delete
                        set((state)=> ({// @ts-ignore
                            users: state.users.map(u => u.id === user.id ? response.data.user : u)
                        }))
                    } else {// Definitive delete
                        set((state)=> ({// @ts-ignore
                            users: state.users.filter(u => u.id !== user.id)
                        }))
                    }
                    return { success: true, error: null }
                },
                
            },
        }),
        { name: 'users', anonymousActionType: 'users.action' }
    )
)
export default useUsersStore