import create from 'zustand'
import type { LayoutStore, LayoutStoreState } from './types'
import maybeDevtools from '../../helpers/zustand'

const initialLayoutState: LayoutStoreState = {
    sidebar: true,
    darkmode: false,
}

const useLayoutStore = create<LayoutStore>(
    maybeDevtools<LayoutStore>(
        (set, get) => ({
            ...initialLayoutState,
            actions: {
                toggleSidebar: () => {
                    set({
                        sidebar: !get().sidebar
                    })
                },
                toggleDarkmode: () => {
                    set({
                        darkmode: !get().darkmode
                    })
                },
            },
        }),
        { name: 'layout', anonymousActionType: 'layout.action' }
    )
)
export default useLayoutStore