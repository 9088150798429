





const fetcher = async <DO = {}>(
    url: string,
    opts?: RequestInit & {
        data?: { [key: string]: any },
        formData?: { [key: string]: any },
        params?: { [key: string]: any },
        session?: boolean,
    }
): Promise<
    | {
        data: DO & { message?: string; code?: string, token?: string },
        success: true,
        error: false,
        status: number,
    }
    | { 
        success: false,
        error: true,
        status: number,
        code: string,
    }
> => {
    opts = { method: "get", ...opts }

    /**
     * Format data
     */
    if ((opts?.method === "post" || opts?.method === "put") && opts.data) {
        opts.body = JSON.stringify(opts.data)
        opts.headers = { "Content-Type": "application/json", ...opts.headers }
    }
    if ((opts?.method === "post" || opts?.method === "put") && opts.formData) {
      const formData = new FormData()
      // @ts-ignore
      Object.keys(opts.formData).forEach(key => formData.append(key, opts.formData[key]))
      opts.body = formData
      // opts.headers = { "Content-Type": "multipart/form-data;", ...opts.headers }
    }

    /**
     * Add authorization
     */
    const token = sessionStorage.getItem('hercule2ApiToken')
    if (token) {
        opts.headers = {...opts.headers, Authorization: 'Bearer ' + token }
    }

    /**
     * Format query params
     */
    const params = new URLSearchParams({ ...opts.params }).toString()
    const URLParams = params ? "?" + params : ""

    try {

        const response = await fetch(url + URLParams, {
            ...opts,
            // credentials: "include",
        })

        /**
         * Parse data
         */
        const contentType = response.headers.get("content-type")
        const isJSON = !!contentType?.includes("application/json")
        if (!isJSON) throw new Error("api fetch: unsupported content type")
        const data = await response.json()

        /**
         * Manage token
         */
        if (opts.session === true && response.status === 200 && data.token) {
            sessionStorage.setItem('hercule2ApiToken', data.token)
        } else if (opts.session === false) {
            sessionStorage.removeItem('hercule2ApiToken')
        }

        /**
         * Response
         */
        const success = response.ok || (response.status >= 200 && response.status <= 299)
        if (success) return {
            success: true,
            error: false,
            status: response.status,
            data,
        }
        return {
            success: false,
            error: true,
            status: response.status,
            code: response.status === 500 ? 'INTERNAL_SERVER_ERROR' : (data.code ?? ''),
        }
    } catch (error) {
        console.error(error)
        return {
            success: false,
            error: true,
            status: 0,
            code: 'FAILED_FETCH',
        }
    }
}

fetcher.get = <DO = {}>(
  url: string,
  opts?: RequestInit & {
    data?: { [key: string]: any },
    params?: { [key: string]: any },
    session?: boolean,
  }
) => fetcher<DO>(url, { method: "get", ...opts })

fetcher.post = <DO = {}>(
  url: string,
  opts?: RequestInit & {
    data?: { [key: string]: any },
    formData?: { [key: string]: any },
    params?: { [key: string]: any },
    session?: boolean,
  }
) => fetcher<DO>(url, { method: "post", ...opts })

fetcher.put = <DO = {}>(
  url: string,
  opts?: RequestInit & {
    data?: { [key: string]: any },
    formData?: { [key: string]: any },
    params?: { [key: string]: any },
    session?: boolean,
  }
) => fetcher<DO>(url, { method: "put", ...opts })

fetcher.delete = <DO = {}>(
    url: string,
    opts?: RequestInit & {
      data?: { [key: string]: any },
      params?: { [key: string]: any },
      session?: boolean,
    }
  ) => fetcher<DO>(url, { method: "delete", ...opts })

export default fetcher


export type One = { [key: string]: any }
export type Many = One[]

export const asOne = (value: any): value is One =>
  value !== null && typeof value === 'object' && !Array.isArray(value)

export const asMaybeOne = (value: any): value is One | null =>
  value === null || asOne(value)

export const asMany = (value: any): value is Many =>
  Array.isArray(value) && value.every(v => asOne(v))
