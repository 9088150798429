import { normalize } from 'app/helpers'
import { useEffectOnce } from 'app/hooks'
import useUsersStore from 'app/store/users'
import useAuthStore from 'app/store/auth'
import Layout from 'layouts/Admin'
import React, { useCallback, useMemo, useState } from 'react'
import ProfileCard from 'components/ProfileCard'
import { Dialog, DialogBackdrop, DialogClose, DialogPannel } from 'components/Dialog'
import { User } from 'app/store/auth/types'
import AdminFormUser from 'components/AdminFormUser'
import { DeepPartial, UserFormData } from 'app/store/users/types'
import Button from 'components/Button'


const Staff: React.FC = () => {
    const isAdmin = useAuthStore(state => state.user?.right === 'admin')
    const users = useUsersStore(state => state.users)
    const { adminUpdate, adminCreate, adminDelete } = useUsersStore(state => state.actions)

    const { getAllUsers, adminGetAllUsers } = useUsersStore(state => ({
        getAllUsers: state.actions.getAllUsers,
        adminGetAllUsers: state.actions.adminGetAllUsers,
    }))
    useEffectOnce(() => { isAdmin ? adminGetAllUsers() :  getAllUsers() })

    const [ displayTrash, setDisplayTrash ] = useState<boolean>(false)

    const [ search, setSearch ] = useState<string>('')
    const filteredUsers = useMemo(() => {

        return users.filter(
            user => normalize(search)
                .split(' ')
                .some(
                    term => (
                        normalize(user.username).includes(term) ||
                        normalize(user.profile.firstname).includes(term) ||
                        normalize(user.profile.lastname).includes(term)
                    )
                ) && (!!user.deletedAt === displayTrash)
        )
    }, [ users, search, displayTrash ])

    const [ deleteDialog, setDeleteDialog ] = useState<User | false>(false)
    const onDelete = useCallback(async () => {
        const user = deleteDialog as User
        setDeleteDialog(false)
        const { success, error } = await adminDelete(user)          
    }, [adminDelete, deleteDialog])

    const [ updateDialog, setUpdateDialog ] = useState<User | false>(false)
    const onUpdate = useCallback(async (formData: DeepPartial<UserFormData>, picture?: File | null) => {
        setUpdateDialog(false)
        const { success, error } = await adminUpdate(formData, picture)
    }, [adminUpdate])
        
    const [ createDialog, setCreateDialog ] = useState(false)
    const onCreate = useCallback( async (formData: UserFormData, picture?:  File) => {
        setCreateDialog(false)
        const { success, error } = await adminCreate(formData, picture)
    }, [adminCreate])



    return (
        <Layout>
            <div className="flex flex-col max-w-sm mx-auto md:max-w-none">
                <nav className="flex gap-8">
                    <div className="relative grow flex">
                        <label 
                            htmlFor="search" 
                            className="absolute top-0 left-0 flex justify-center items-center w-16 h-16"
                        >
                            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M507.312 484.688L365.85 343.227C397.041 306.848 416 259.676 416 208C416 93.125 322.875 0 208 0S0 93.125 0 208S93.125 416 208 416C259.676 416 306.848 397.039 343.225 365.852L484.687 507.312C487.812 510.438 491.906 512 496 512S504.188 510.438 507.312 507.312C513.562 501.062 513.562 490.938 507.312 484.688ZM208 384C110.953 384 32 305.047 32 208S110.953 32 208 32S384 110.953 384 208S305.047 384 208 384Z"/></svg>
                        </label>
                        <input
                            onChange={({ target }) => setSearch(target.value)}
                            value={search}
                            placeholder="Search..."
                            name="search"
                            id="search"
                            className="grow w-full h-16 px-16 rounded-lg bg-white shadow-lg shadow-blue-700 text-lg"
                        />
                        {!!search && (
                            <button 
                                className="absolute top-0 right-0 flex justify-center items-center w-16 h-16"
                                onClick={() => setSearch('')}
                            >
                                <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.31 411.31C309.056 417.563 298.936 417.563 292.682 411.31L160 278.627L27.318 411.31C21.064 417.563 10.944 417.563 4.69 411.31C-1.563 405.056 -1.563 394.936 4.69 388.682L137.373 256L4.69 123.318C-1.563 117.064 -1.563 106.944 4.69 100.69C10.944 94.437 21.064 94.437 27.318 100.69L160 233.373L292.682 100.69C298.936 94.437 309.056 94.437 315.31 100.69C321.563 106.944 321.563 117.064 315.31 123.318L182.627 256L315.31 388.682C321.563 394.936 321.563 405.056 315.31 411.31Z"/></svg>
                            </button>
                        )}
                    </div>
                    {
                        isAdmin && (
                            <>
                                <button 
                                    className="flex justify-center items-center w-16 h-16 rounded-full bg-lime-500 hover:bg-lime-600 shadow-lg shadow-blue-700 text-white"
                                    onClick={() => setCreateDialog(true)}
                                >
                                    <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256C432 264.844 424.844 272 416 272H240V448C240 456.844 232.844 464 224 464S208 456.844 208 448V272H32C23.156 272 16 264.844 16 256S23.156 240 32 240H208V64C208 55.156 215.156 48 224 48S240 55.156 240 64V240H416C424.844 240 432 247.156 432 256Z"/></svg>
                                </button>
                                <button 
                                    className="flex justify-center items-center w-16 h-16 rounded-lg bg-white hover:bg-neutral-100 shadow-lg shadow-blue-700"
                                    onClick={() => setDisplayTrash(!displayTrash)}
                                >
                                    {displayTrash ? (
                                        <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M527.999 96C536.8 96 543.999 88.799 543.999 80C543.999 71.199 536.8 64 527.999 64H431.999L398.374 19.25C389.374 7.125 375.124 0 359.999 0H279.999C264.874 0 250.624 7.125 241.624 19.25L207.999 64H180.114L220.648 96H527.999ZM267.249 38.375C270.249 34.375 274.999 32 279.999 32H359.999C364.999 32 369.749 34.375 372.749 38.375L391.999 64H247.999L267.249 38.375ZM480.839 142.576L467.583 290.955L497.595 314.648L512.71 145.422C513.548 136.061 506.173 128 496.775 128C488.489 128 481.575 134.324 480.839 142.576ZM451.999 465.375C451.374 473.625 444.374 480 436.124 480H203.874C195.624 480 188.624 473.625 187.999 465.375L165.696 215.721L131.13 188.43L156.124 468.25C158.374 493 178.999 512 203.874 512H436.124C460.999 512 481.624 493 483.874 468.25L483.985 467.01L453.97 443.312L451.999 465.375ZM633.921 483.436L25.921 3.42C18.984 -2.033 8.921 -0.83 3.437 6.061C-2.032 12.998 -0.845 23.061 6.077 28.545L614.077 508.562C617.015 510.875 620.515 512 623.984 512C628.718 512 633.405 509.906 636.562 505.922C642.03 498.984 640.843 488.92 633.921 483.436Z"/></svg>
                                    ) : (
                                        <svg className="w-5 h-5 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400.774 128C392.49 128 385.575 134.324 384.839 142.576L356 465.375C355.375 473.625 348.375 480 340.125 480H107.875C99.625 480 92.625 473.625 92 465.375L63.162 142.576C62.425 134.324 55.51 128 47.226 128C37.827 128 30.453 136.062 31.289 145.423L60.125 468.25C62.375 493 83 512 107.875 512H340.125C365 512 385.625 493 387.875 468.25L416.711 145.423C417.548 136.062 410.173 128 400.774 128ZM432 64H336L302.375 19.25C293.375 7.125 279.125 0 264 0H184C168.875 0 154.625 7.125 145.625 19.25L112 64H16C7.2 64 0 71.2 0 80S7.2 96 16 96H432C440.8 96 448 88.8 448 80S440.8 64 432 64ZM152 64L171.25 38.375C174.25 34.375 179 32 184 32H264C269 32 273.75 34.375 276.75 38.375L296 64H152Z"/></svg>
                                    )}
                                </button>
                            </>
                        )
                    }
                </nav>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mt-12 gap-12">
                    {filteredUsers.map(user => (
                        <ProfileCard user={user} key={`staff-${user.id}`} onEdit={() => setUpdateDialog(user)} onDelete={() => setDeleteDialog(user)} />
                    ))}
                </div>
            </div>
            

            <Dialog open={!!deleteDialog} onClose={() => setDeleteDialog(false)}>
                <DialogBackdrop />
                <DialogPannel className="max-w-3xl" center>
                    <DialogClose />
                        <h1 className="py-2 mb-12 text-4xl font-bold text-center leading-8">Are you <span className="text-blue-500">sure ?</span></h1>
                        <div className="flex justify-center gap-8">
                            <Button secondary onClick={() => setDeleteDialog(false)}>Discard</Button>
                            <Button onClick={onDelete}>Hmmm... yes</Button>
                        </div>
                </DialogPannel>
            </Dialog>


            <Dialog open={!!updateDialog} onClose={() => setUpdateDialog(false)} onClickOutside={() => setUpdateDialog(false)}>
                <DialogBackdrop />
                <DialogPannel className="max-w-3xl">
                    <DialogClose />
                    <h1 className="py-2 mb-12 text-4xl font-bold text-center leading-8">Update user <span className="text-blue-500">informations</span></h1>
                    <AdminFormUser user={updateDialog as User}  onSave={onUpdate} onCancel={() => setUpdateDialog(false)} />
                </DialogPannel>
            </Dialog>


            <Dialog open={createDialog} onClose={() => setCreateDialog(false)}>
                <DialogBackdrop />
                <DialogPannel className="max-w-3xl">
                    <DialogClose />
                    <h1 className="py-2 mb-12 text-4xl font-bold text-center leading-8">Create a <span className="text-blue-500">new user</span></h1>
                    <AdminFormUser onSave={(formData, picture) => onCreate(formData as UserFormData, picture as File | undefined)} onCancel={() => setCreateDialog(false)} />
                </DialogPannel>
            </Dialog>
        </Layout>
    )

}
export default Staff