import { FC } from 'react'
import useEffectOnce from 'app/hooks/useEffectOnce'
import useUserStore from 'app/store/auth'



const SignOut: FC = () => {
    const signOut = useUserStore(state => state.actions.signOut)
    useEffectOnce(() => {
        signOut()
    })
    return <></>
}
export default SignOut