
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Link, useLocation } from 'wouter'
import { useElementScrollSize, useEventListener, useOnClickOutside, useResponsive } from 'app/hooks'
import { vClass } from 'app/helpers'
import useLayoutStore from 'app/store/layout'
import useUserStore from 'app/store/auth'






type Props = {
    menu: string
    setMenu: React.Dispatch<string>
}

const Sidebar : React.FC<Props> = ({ menu, setMenu }) => {
    const isAdmin = useUserStore(state => state.user?.right === 'admin')
    const { sidebar, toggleSidebar } = useLayoutStore(state => ({
        sidebar: state.sidebar, 
        toggleSidebar: state.actions.toggleSidebar,
    }))

    const menuScrollRef = useRef<HTMLDivElement>(null)
    const [ menuScrollTop, setMenuScrollTop ] = useState(0)
    const refreshMenuScrollTop = useCallback(() => {
        if (!menuScrollRef.current) return
        const top =  Math.round(menuScrollRef.current.scrollTop)
        if (top === menuScrollTop) return
        setMenuScrollTop(top)
    }, [ menuScrollRef, menuScrollTop ])
    useEventListener('resize', refreshMenuScrollTop)
    const media = useResponsive()
    return (
        <>
            <aside 
                className={vClass(
                    'fixed z-20 left-0 bottom-0 top-0',
                    'grid',
                    'bg-neutral-800 text-white',
                    'transition-all duration-300 ease-in-out',
                    sidebar ? 'w-0 md:w-16 lg:w-20' : 'w-4/5 sm:w-96',
                )}
                style={{ gridTemplateRows: media.min('lg') ? '5rem 1fr' : media.min('md') ? '4rem 1fr' : '3rem 1fr' }}
            >
                <div className={vClass(
                    'flex flex-col h-12 md:h-16 lg:h-20',
                )}> 
                    <a href="/dashboard" className={vClass(
                        'flex justify-start items-center h-12 md:h-16 lg:h-20',
                        'transition-all duration-300 ease-in-out',
                        sidebar ? 'w-0 md:w-16 lg:w-20 rotate-90 md:px-2 lg:px-4' : 'w-32 rotate-0 px-8',

                    )}>
                        <svg className={vClass(
                            'fill-current',
                            'transition-all duration-300 ease-in-out',
                            sidebar ? 'w-8 md:w-12 lg:w-12' : 'w-8 md:w-12 lg:w-16',
                        )} viewBox="0 0 64 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.61178 0.308715L-0.000961547 5.88333L-0.000961886 13.6192L6.61178 8.06172L6.61178 20L14.04 20L14.04 0.308716L6.61178 0.308715Z"></path><path d="M64 20L64 0.308716L56.5717 0.308715L49.9413 5.88333L49.9413 13.6192L56.5717 8.06172L56.5717 20L64 20Z"></path><path d="M44.7471 20L44.7471 11.7153C44.7471 4.2024 39.0385 -2.4953e-07 32.408 -5.39357e-07C25.7775 -8.29184e-07 20.0158 4.2024 20.0158 11.7153L20.0158 20L27.444 20L27.444 11.8182C27.444 8.50772 29.5005 6.4494 32.408 6.4494C35.3155 6.4494 37.3188 8.49057 37.3188 11.8182L37.3188 20L44.7471 20Z"></path></svg>
                    </a>
                </div>

                <div className="overflow-y-auto direction-rtl scrollbar-thin" ref={menuScrollRef} onWheel={refreshMenuScrollTop}>
                    <ul className="flex flex-col direction-ltr">
                        <SidebarLink
                            label={`Dashboard`}
                            labelIcon={(
                                <svg className={'fill-current w-6 h-6'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M120 320C120 333.25 109.25 344 96 344S72 333.25 72 320S82.75 296 96 296S120 306.75 120 320ZM288 152C301.25 152 312 141.25 312 128S301.25 104 288 104S264 114.75 264 128S274.75 152 288 152ZM152 160C138.75 160 128 170.75 128 184S138.75 208 152 208S176 197.25 176 184S165.25 160 152 160ZM433.999 171.5C441 177.125 441.999 187.25 436.499 194L339.25 314C347.125 324.75 352 337.75 352 352C352 387.375 323.375 416 288 416S224 387.375 224 352S252.625 288 288 288C297.5 288 306.375 290.125 314.5 293.875L411.5 174C417.125 167 427.125 166 433.999 171.5ZM320 352C320 334.375 305.625 320 288 320S256 334.375 256 352C256 369.625 270.375 384 288 384S320 369.625 320 352ZM480 296C466.75 296 456 306.75 456 320S466.75 344 480 344C493.25 344 504 333.25 504 320S493.25 296 480 296ZM576 320C576 372.75 561.75 422.25 537 464.75C531.375 474.375 520.625 480 509.5 480H66.5C55.375 480 44.625 474.375 39 464.75C14.25 422.25 0 372.75 0 320C0 161 129 32 288 32S576 161 576 320ZM544 320C544 178.875 429.125 64 288 64S32 178.875 32 320C32 365.25 44 409.125 66.75 448H509.5C532 409.75 544 365.25 544 320Z"/></svg>
                            )}
                            href="/dashboard"
                        />
                        <SidebarLink
                            label={`Staff`}
                            labelIcon={(
                                <svg className={'fill-current w-6 h-6'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M319.859 320C377.273 320 423.814 273.436 423.814 216C423.814 158.562 377.273 112 319.859 112C262.451 112 215.904 158.562 215.904 216C215.904 273.436 262.451 320 319.859 320ZM319.859 144C359.535 144 391.814 176.299 391.814 216S359.535 288 319.859 288S247.904 255.701 247.904 216S280.184 144 319.859 144ZM369.887 352H270.113C191.631 352 128 411.693 128 485.332C128 500.059 140.727 512 156.422 512H483.578C499.273 512 512 500.059 512 485.332C512 411.693 448.377 352 369.887 352ZM160.15 480C163.172 426.592 211.338 384 270.113 384H369.887C428.662 384 476.828 426.592 479.85 480H160.15ZM512 160C556.184 160 592 124.182 592 80S556.184 0 512 0C467.82 0 432 35.818 432 80S467.82 160 512 160ZM512 32C538.467 32 560 53.533 560 80S538.467 128 512 128S464 106.467 464 80S485.533 32 512 32ZM128 160C172.184 160 208 124.182 208 80S172.184 0 128 0C83.82 0 48 35.818 48 80S83.82 160 128 160ZM128 32C154.467 32 176 53.533 176 80S154.467 128 128 128S80 106.467 80 80S101.533 32 128 32ZM561.078 192H496C484.844 192 473.922 194.5 463.531 199.438C455.547 203.234 452.141 212.781 455.938 220.75S469.313 232.141 477.25 228.344C483.328 225.469 489.625 224 496 224H561.078C586.953 224 608 246.734 608 274.672V288C608 296.844 615.156 304 624 304S640 296.844 640 288V274.672C640 229.094 604.594 192 561.078 192ZM162.75 228.344C170.688 232.141 180.281 228.719 184.062 220.75C187.859 212.781 184.453 203.234 176.469 199.438C166.078 194.5 155.156 192 144 192H78.922C35.406 192 0 229.094 0 274.672V288C0 296.844 7.156 304 16 304S32 296.844 32 288V274.672C32 246.734 53.047 224 78.922 224H144C150.375 224 156.672 225.469 162.75 228.344Z"/></svg>
                            )}
                            href="/dashboard/staff"
                        />
                        {isAdmin && false && ( 
                            <>
                                <SidebarMenu { ...{ sidebar, menu, setMenu, menuScrollTop } }
                                    index={`user`}
                                    label={`Users`}
                                    labelIcon={(
                                        <svg className={'fill-current w-6 h-6'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M319.859 320C377.273 320 423.814 273.436 423.814 216C423.814 158.562 377.273 112 319.859 112C262.451 112 215.904 158.562 215.904 216C215.904 273.436 262.451 320 319.859 320ZM319.859 144C359.535 144 391.814 176.299 391.814 216S359.535 288 319.859 288S247.904 255.701 247.904 216S280.184 144 319.859 144ZM369.887 352H270.113C191.631 352 128 411.693 128 485.332C128 500.059 140.727 512 156.422 512H483.578C499.273 512 512 500.059 512 485.332C512 411.693 448.377 352 369.887 352ZM160.15 480C163.172 426.592 211.338 384 270.113 384H369.887C428.662 384 476.828 426.592 479.85 480H160.15ZM512 160C556.184 160 592 124.182 592 80S556.184 0 512 0C467.82 0 432 35.818 432 80S467.82 160 512 160ZM512 32C538.467 32 560 53.533 560 80S538.467 128 512 128S464 106.467 464 80S485.533 32 512 32ZM128 160C172.184 160 208 124.182 208 80S172.184 0 128 0C83.82 0 48 35.818 48 80S83.82 160 128 160ZM128 32C154.467 32 176 53.533 176 80S154.467 128 128 128S80 106.467 80 80S101.533 32 128 32ZM561.078 192H496C484.844 192 473.922 194.5 463.531 199.438C455.547 203.234 452.141 212.781 455.938 220.75S469.313 232.141 477.25 228.344C483.328 225.469 489.625 224 496 224H561.078C586.953 224 608 246.734 608 274.672V288C608 296.844 615.156 304 624 304S640 296.844 640 288V274.672C640 229.094 604.594 192 561.078 192ZM162.75 228.344C170.688 232.141 180.281 228.719 184.062 220.75C187.859 212.781 184.453 203.234 176.469 199.438C166.078 194.5 155.156 192 144 192H78.922C35.406 192 0 229.094 0 274.672V288C0 296.844 7.156 304 16 304S32 296.844 32 288V274.672C32 246.734 53.047 224 78.922 224H144C150.375 224 156.672 225.469 162.75 228.344Z"/></svg>
                                    )}
                                    children={[
                                        { name: 'Display users', path: '/' },
                                        { name: 'Create a new user', path: '/' },
                                    ]}
                                />
                                <SidebarMenu { ...{ sidebar, menu, setMenu, menuScrollTop } }
                                    index={`hacks`}
                                    label={`Hacks`}
                                    labelIcon={(
                                        <svg className={'fill-current w-6 h-6'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M288 128H160C124.75 128 96 156.75 96 192V208C96 269.75 146.25 320 208 320H240C301.75 320 352 269.75 352 208V192C352 156.75 323.25 128 288 128ZM320 208C320 252.125 284.125 288 240 288H208C163.875 288 128 252.125 128 208V192C128 174.375 142.25 160 160 160H288C305.75 160 320 174.375 320 192V208ZM304 400H144C117.49 400 96 421.49 96 448V496C96 504.8 103.2 512 112 512H112C120.8 512 128 504.8 128 496V448C128 439.2 135.2 432 144 432H304C312.8 432 320 439.2 320 448V496C320 504.8 327.2 512 336 512H336C344.8 512 352 504.8 352 496V448C352 421.49 330.51 400 304 400ZM272 448C263.25 448 256 455.25 256 464S263.25 480 272 480S288 472.75 288 464S280.75 448 272 448ZM176 448C167.25 448 160 455.25 160 464V496C160 504.8 167.2 512 176 512H176C184.8 512 192 504.8 192 496V464C192 455.25 184.75 448 176 448ZM359.25 328.25C379.5 308.25 395.125 283.5 404.875 256H416C424.8 256 432 248.8 432 240V144C432 135.2 424.8 128 416 128H404.75C378.5 53.5 307.625 0 224 0S69.5 53.5 43.25 128H32C23.2 128 16 135.2 16 144V240C16 248.8 23.2 256 32 256H43.25C52.875 283.5 68.625 308.25 88.875 328.25C37.125 347 0 396.25 0 454.375V496C0 504.8 7.2 512 16 512H16C24.8 512 32 504.8 32 496V454.375C32 402.75 70.5 360.375 120.25 353.375C150.25 372.75 185.75 384 224 384S297.75 372.75 327.75 353.375C377.5 360.25 416 402.75 416 454.375V496C416 504.8 423.2 512 432 512L432 512C440.8 512 448 504.8 448 496V454.375C448 396.25 410.875 347 359.25 328.25ZM224 352C135.75 352 64 280.25 64 192S135.75 32 224 32S384 103.75 384 192S312.25 352 224 352Z"/></svg>
                                    )}
                                    children={[
                                        { name: 'Hercule campaigns', path: 'admin/hacks/campaigns' },
                                    ]}
                                />
                            </>
                        )}
                    </ul>
                </div>

            </aside>
            {!sidebar && (
                <button 
                    className="fixed z-[19] inset-0 bg-neutral-900/50 backdrop-blur-sm animate-fade-in"
                    onClick={() => toggleSidebar()}
                />
            )}
        </>
    )
}
export default Sidebar


type MenuProps = {
    sidebar: boolean
    menu: string
    setMenu: (item: string) => void
    index: string
    label: string
    labelIcon: JSX.Element
    children: { name: string, path: string }[],
    menuScrollTop: number
}
const SidebarMenu: React.FC<MenuProps> = ({ menuScrollTop, sidebar, menu, setMenu, index, label, labelIcon, children }) => {

    const buttonRef = useRef<HTMLDivElement>(null)

    const toggleMenu = useCallback<React.MouseEventHandler>(() => {
        setMenu(menu === index ? '' : index)
    }, [ setMenu, menu, index ])
    
    const menuIsOpen = useMemo(() => {
        return menu === index
    }, [ menu, index ])
    
    /**
     * Backdrop
     */
    const menuRef = useRef<HTMLLIElement>(null)
    useOnClickOutside(
        menuRef, 
        () => sidebar && setMenu('')
    )

    /**
     * Popover
     */
    const [ popoverRef, popoverSize ] = useElementScrollSize<HTMLUListElement>()
    const popoverStyle = useMemo(() => {
        if (!(buttonRef.current)) return {}
        const offsetWidth = buttonRef.current.offsetWidth
        const popoverHeight = popoverSize.height
        const offsetTop = buttonRef.current.offsetTop - menuScrollTop
        if (window.innerHeight < popoverHeight + buttonRef.current.offsetTop - menuScrollTop)
            return { 
                bottom: '0px',
                left: offsetWidth + 'px',
            }
        else return { 
                top: offsetTop + 'px',
                left: offsetWidth + 'px',
            }
    }, [ buttonRef, menuScrollTop, popoverSize.height ])

    return (
        <li ref={menuRef}>
            <div 
                className={'w-full overflow-hidden bg-neutral-800'}
                ref={buttonRef}
            >
                <button 
                    className="flex justify-center items-center w-96 h-20"
                    onClick={toggleMenu}
                >
                    <span className="flex justify-center items-center w-20 h-20">{labelIcon}</span>
                    <span className={'grow text-lg font-light text-left overflow-hidden'}>{label}</span>
                    <span className={'flex justify-center items-center w-20 h-20'}>
                        <svg className={vClass(
                            'fill-current w-6 h-6 transition-all duration-300 ease-in-out',
                            menuIsOpen ? 'rotate-180' : 'rotate-0'
                        )} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M362.71 203.889L202.719 347.898C196.594 353.367 187.407 353.367 181.282 347.898L21.292 203.889C14.729 197.982 14.198 187.857 20.104 181.295C26.376 174.377 36.499 174.512 42.729 180.107L192.001 314.475L341.272 180.107C347.866 174.23 357.96 174.746 363.897 181.295C369.803 187.857 369.272 197.982 362.71 203.889Z"/></svg>
                    </span>
                </button>
            </div>
            <div 
                style={sidebar ? popoverStyle : { height: menuIsOpen ? popoverSize.height : 0 }}
                className={vClass(
                    sidebar ? 
                        'absolute z-10 flex w-max ml-4' :
                        'flex flex-col overflow-hidden transition-all duration-300 ease-in-out'
                )} 
            >
                {((!sidebar && menuIsOpen) || (sidebar && menuIsOpen)) && (
                    <ul 
                        ref={popoverRef}
                        className={vClass(
                            sidebar ? 
                                'relative z-10 flex flex-col rounded-lg py-4 bg-neutral-900 animate-fade-in' :
                                'flex flex-col overflow-hidden transition-all duration-300 ease-in-out'
                        )} 
                    >
                        {sidebar && menuIsOpen && (
                            <div className="absolute top-0 right-full flex items-center ml-2 -mr-2 h-20">
                                <span className="flex w-4 h-4 rotate-45 bg-neutral-900"></span>
                            </div>
                        )}
                        {children.map((item, index) => (
                            <li key={`sidebar-menu-${index}`}>
                                <Link href={item.path} className="flex items-center px-8 py-2 gap-8 hover:text-blue-400">
                                    <svg className="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M219.898 266.719L75.888 426.709C69.982 433.271 59.857 433.803 53.295 427.896C46.377 421.625 46.511 411.502 52.107 405.271L186.474 256L52.107 106.729C46.23 100.135 46.746 90.041 53.295 84.104C59.857 78.197 69.982 78.729 75.888 85.291L219.898 245.281C225.367 251.406 225.367 260.594 219.898 266.719Z"/></svg>
                                    <span className="grow text-lg font-light">{item.name}</span>
                                </Link>
                            </li>                
                        ))}
                    </ul>
                )}
            </div>
        </li>
    )
}


type LinkProps = {
    href: string
    label: string
    labelIcon: JSX.Element
}

const SidebarLink: React.FC<LinkProps> = ({
    label,
    labelIcon,
    href,   
}) => {
    const [ location ] = useLocation()
    return (
        <li>
                <Link 
                    href={href}
                    className={vClass(
                        'flex justify-center items-center w-96 h-12 md:h-16 lg:h-20',
                        location === href ? 'text-blue-400' : '',
                    )}
                >
                    <span className="flex justify-center items-center w-20 h-20">{labelIcon}</span>
                    <span className={'grow text-lg font-light text-left overflow-hidden'}>{label}</span>
                </Link>
        </li>
    )
}