import { FC } from 'react'


const SessionLoader: FC = () => {
    return (
        <div className="fixed inset-0 flex justify-center items-center">
            <span className="loader"></span>
        </div>
    )
}
export default SessionLoader