import React, { useRef } from "react"
import { vClass } from "app/helpers"

type Props = {
    value: string
    setValue: React.Dispatch<string>
    labelIcon?: JSX.Element
    placeholder?: string
    name?: string
    password?: boolean
    isValid?: boolean
    validate?: boolean
    className?: string
}

/**
 * Form Text
 */
const FormTextarea: React.FC<Props> = ({ 
    value, 
    setValue, 
    name = 'form-text', 
    labelIcon, 
    placeholder = '', 
    className, 
    isValid, 
    validate 
}) => {
    const fieldRef = useRef<HTMLTextAreaElement>(null)

    /**
     * Render
     */
    return (
        <div className="relative flex w-full">
            {!!labelIcon && (
                <label 
                    htmlFor={name}
                    className="absolute inset-y-0 left-0 flex justify-center items-center w-12 h-12 text-neutral-500"
                    aria-hidden="true"
                >
                    {labelIcon}
                </label>
            )}
            <textarea 
                className={vClass(
                    'flex justify-between items-center grow py-3 scrollbar-thin leading-6 bg-white shadow rounded-lg text-lg text-left placeholder:text-neutral-500',
                    labelIcon ? 'pl-12' : 'pl-4',
                    className ?? 'h-32 resize-none',
                    {
                        'border border-red-200 shadow-red-200': validate && !isValid
                    }
                )}
                id={name}
                name={name}
                ref={fieldRef}
                value={value}
                onChange={({ target }) => setValue(target.value)}
                placeholder={placeholder}
            />
        </div>
    )
}
export default FormTextarea