import Layout from 'layouts/Admin'
import React from 'react'







const Admin: React.FC = () => {
    return (
        <Layout>
            
        </Layout>
    )

}
export default Admin