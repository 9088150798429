import { vClass } from "app/helpers"
import React from "react"
import { Link } from "wouter"




// type Props = {
//     className?: string
//     secondary?: boolean
//     success?: boolean
//     danger?: boolean
// } & ({
//     as: undefined | 'button'
//     onClick?: React.MouseEventHandler<HTMLButtonElement>
//     type?: 'button' | 'submit'
// } | {
//     as: 'link'
//     href?: string
//     blank?: boolean
// })


type Props = {
    className?: string,
    secondary?: boolean,
    success?: boolean,
    danger?: boolean,
    as?: 'button' | 'link',
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    type?: 'button' | 'submit',
    href?: string,
    blank?: boolean,
}
const Button: React.FC<React.PropsWithChildren<Props>> = ({
    className, 
    secondary, 
    success, 
    danger,
    as = 'button',
    onClick = () => {},
    type = 'button',
    href,
    blank = false,
    children
}) => {
    

    if (as === 'button') {


        return (
            <button
                className={vClass(
                    'flex items-center justify-center gap-2  px-4 py-2 shadow-sm rounded leading-8 transition-colors duration-300 ease-in-out',
                    secondary ? 'bg-white hover:bg-neutral-50 border text-neutral-800' :
                    success ? 'bg-lime-500 hover:bg-lime-600 text-white' :
                    danger ? 'bg-red-500 hover:bg-red-600 text-white' :
                    'bg-blue-500 hover:bg-blue-600 text-white',
                    className,
                )}
                type={type}
                onClick={onClick}
            >{children}</button>
        )
    } else if (as === 'link' && blank) {
        return (
            <a 
                href={href} 
                target="_blank"
                rel="noopener noreferrer nofollow"
                className={vClass(
                    'flex items-center justify-center gap-2  px-4 py-2 shadow-sm rounded leading-8 transition-colors duration-300 ease-in-out',
                    secondary ? 'bg-white hover:bg-neutral-50 border text-neutral-800' :
                    success ? 'bg-lime-500 hover:bg-lime-600 text-white' :
                    danger ? 'bg-red-500 hover:bg-red-600 text-white' :
                    'bg-blue-500 hover:bg-blue-600 text-white',
                    className,
                )}
            >{children}</a>
        )
    }  else if (as === 'link') {
        return (
            <Link 
                href={href ?? '/'} 
                className={vClass(
                    'flex items-center justify-center gap-2  px-4 py-2 shadow-sm rounded leading-8 transition-colors duration-300 ease-in-out',
                    secondary ? 'bg-white hover:bg-neutral-50 border text-neutral-800' :
                    success ? 'bg-lime-500 hover:bg-lime-600 text-white' :
                    danger ? 'bg-red-500 hover:bg-red-600 text-white' :
                    'bg-blue-500 hover:bg-blue-600 text-white',
                    className,
                )}
            >{children}</Link>
        )
    } else return (<></>)
}

export default Button